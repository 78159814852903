import React, { useState } from 'react';
import './footer.scss';
import { Link } from 'react-router-dom';

import logoWhite from '../../../multimedia/images/LOGO/black/HenLeiMD.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Errors from '../error/Errors';

import axios from 'axios';
import { endpoint_service_ins_email } from '../../../enviroment/enviroment';
import { EmailBlog } from '../../../models/EmailBlog';
import Swal from 'sweetalert2';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';


const Footer = () => {

    const [emailUser, saveEmail] = useState('');
    const [messageError, saveMessageError] = useState([]);
    const [error, saveError] = useState(false);

    const [loader, saveLoader] = useState(null);
    const [disab, saveDisab] = useState(false);


    const addEmail = async e => {
        e.preventDefault();
        let txtErr = [];

        //  !(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

        if (!(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(emailUser)) || emailUser === '') {
            txtErr.push('Por favor ingresa un email valido');
            saveMessageError(txtErr);
            saveError(true);
            return;
        }

        saveError(false);

        try {

            saveLoader(<FontAwesomeIcon icon="circle-notch" className="spinnerLoaderFoot"/>);
            saveDisab(true);
            let emailBlog = new EmailBlog();

            const URL = endpoint_service_ins_email;

            /* emailBlog.fechaRegistro = Date.now(); */
            emailBlog.email = emailUser;
            /* emailBlog.activo = 0; */

            const result = await axios.post("" + URL, emailBlog, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            saveEmail('');
            

            if (result.status === 201) {
                Swal.fire({
                    title: '<b class="titleSwal">Gracias por registrarte</b>',
                    html: 'Pronto te enviaremos nuevos artículos y contenidos' +
                        '<br/>' +
                        'Si deseas puedes acceder a nuestro' +
                        '<b><a href="https://blog.henleimd.com/" target="_blank" rel="noopener noreferrer"> Blog</a></b>',
                    icon: 'success',
                    confirmButtonText: '¡ Vale !',
                    focusConfirm: false
                })

            } else if (result.status === 208) {
                Swal.fire({
                    title: '<b class="titleSwal">Gracias</b>',
                    icon: 'info',
                    html:
                        'Tu correo  ya se encuentra registrado.' +
                        '<br/>' +
                        'Si deseas puedes acceder a nuestro' +
                        '<b><a href="https://blog.henleimd.com/" target="_blank" rel="noopener noreferrer"> Blog</a></b>',
                    confirmButtonText: '¡ Vale !',
                    focusConfirm: false

                })
            } else {
                Swal.fire({
                    title: '<b class="titleSwal">Error</b>',
                    text: 'Algo ha salido mal por favor refresca la página y vuelve a intentar',
                    icon: 'error',
                    confirmButtonText: '¡ Vale !',
                    focusConfirm: false
                })
            }

        } catch (error) {
            Swal.fire({
                title: '<b class="titleSwal">Error</b>',
                text: 'Algo ha salido mal por favor refresca la página y vuelve a intentar',
                icon: 'error',
                confirmButtonText: '¡ Vale !',
                focusConfirm: false
            })
        }
        saveLoader(null);
        saveDisab(false);

    }

    return (
        <Zoom>
            <footer id="footer">
                <div className="bgWhite">
                    <div className="container-fluid footer">
                        <Zoom delay={200}>
                            <div className="row justify-content-around">
                                {/* Logo */}
                                <div className="col-10 col-md-5 col-lg-3 logo text-center">
                                    <Link to="/"><img src={logoWhite} alt="HenLei MD Logo" /></Link>
                                </div>
                            </div>
                        </Zoom>
                        <div className="row justify-content-sm-around">
                            {/* <!-- servicios --> */}


                            <Fade left delay={600}>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 text-center text-sm-left slogan">
                                    <h4><span>¡</span> Cambia Tu Mundo <span>!</span></h4>
                                </div>
                            </Fade>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 text-center text-sm-left especialidades">
                                <ul className="list-group list-group-flush d-inline-flex">
                                    <Fade top delay={200}><Link to="/marketing"><li className="list-group-item">Marketing</li></Link></Fade>
                                    <Fade top delay={400}><Link to="/desarrollo"><li className="list-group-item">Desarrollo Web </li></Link></Fade>
                                    <Fade top delay={600}><Link to="/apps"><li className="list-group-item">Desarrollo de Apps </li></Link></Fade>
                                    <Fade top delay={800}><Link to="/socialmedia"><li className="list-group-item">Social Media </li></Link></Fade>
                                </ul>
                            </div>

                            {/* <!-- sucribirse al blog --> */}
                            <div className="col-12 col-sm-12 col-md-7 col-lg-4  text-center text-sm-left blogFooter">
                                <Fade top delay={400}>
                                    <h4>Suscríbete a nuestro Blog</h4>
                                </Fade>
                                <Fade delay={600}>
                                    <form onSubmit={addEmail}>
                                        <div className="form-row justify-content-center">
                                            <div className="col-6 nopadding">
                                                <input type="email"
                                                    name="emailBlog"
                                                    className="form-control"
                                                    id="emailBlog"
                                                    placeholder="Email"
                                                    required
                                                    value={emailUser}
                                                    onChange={(e) => saveEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-5 col-sm-4 nopadding">
                                                <button type="submit" className="btn btn-primary btnhh" disabled={disab}>Suscribirme</button>
                                                {
                                                    loader
                                                }
                                            </div>
                                            {(error) ? <Errors message={messageError} /> : null}
                                        </div>

                                    </form>
                                </Fade>
                                <div className="socialFollow">
                                    <Fade top delay={200}>
                                        <h4>Síguenos</h4>
                                    </Fade>
                                    <div className="col iconos text-center">
                                        <Bounce top delay={400}>
                                            <a href="https://fb.me/henleimd" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="cotactIcon2" icon={['fab', 'facebook-f']} /></a>
                                        </Bounce>
                                        <Bounce top delay={600}>
                                            <a href="https://twitter.com/henleimd/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="cotactIcon2" icon={['fab', 'twitter']} /></a>
                                        </Bounce>
                                        <Bounce top delay={800}>
                                            <a href="https://www.instagram.com/henleimd/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="cotactIcon2" icon={['fab', 'instagram']} /></a>
                                        </Bounce>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <Zoom delay={400}>
                            <div className="row MSyC">
                                {/* <!-- mapa sitio --> */}
                                <div className="col-12 col-lg-6 mapaSitio">
                                    <div className="row">
                                        <div className="col-12 col-sm-4 col-lg-3 text-center"><Link to="/">Inicio</Link></div>
                                        <div className="col-12 col-sm-4 col-lg-3 text-center"><Link to="/terminos">Términos y Condiciones</Link></div>
                                        <div className="col-12 col-sm-4 col-lg-3 text-center"><a href="https://blog.henleimd.com/" target="_blank" rel="noopener noreferrer">Blog</a></div>
                                    </div>
                                </div>

                                {/* <!-- copyright --> */}
                                <div className="col-12 col-lg-6 text-center text-lg-right copyright">
                                    <h5>© Copyright. Todos los derechos reservados</h5>
                                </div>
                            </div>
                        </Zoom>
                    </div>

                </div>

            </footer>

        </Zoom>
    );
}

export default Footer;