export class EmailBlog {
    idEmail;
    fechaRegistro; 
    email;
    activo;

   /* constructor(fechaRegistro, email, activo){
        this._fechaRegistro = fechaRegistro;
        this._email = email;
        this._activo = activo;
    }

    getFechaRegistro (){
        return this._fechaRegistro;
    }

    getEmail (){
        return this._email;
    }

    getActivo (){
        return this._activo;
    }

    setFechaRegistro (fechaRegistro){
        return this._fechaRegistro = fechaRegistro;
    }

    setEmail (email){
        return this._email = email;
    }

    setActivo (activo){
        return this._activo = activo;
    }    */
}