import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './sectionContactChat.scss';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

const SectionContactChat = () => {
    return (
        <Fade>
            <section className="contacto" id="contactChatSect">
                <div className="container-fluid bgblack">
                    <div className="row justify-content-end">


                        <div className="col-12 col-md-10 col-lg-6 col-xl-5 datosContacto">
                            <Zoom delay={200}>
                                <h4 className="text-center">Contáctanos</h4>
                            </Zoom>

                            <div className="row justify-content-around">

                                <Fade left cascade delay={200}>
                                    <div className="col-11 col-sm-6 phones">
                                        <p><FontAwesomeIcon className="cotactIcon clOralnge" icon="phone" />(+57 1) 789 3044</p>
                                        {/* <hr /> */}
                                        <p><FontAwesomeIcon className="cotactIcon clOralnge" icon="mobile-alt" />(+57) 313 473 6826</p>
                                        {/* <hr /> */}
                                        <p><FontAwesomeIcon className="cotactIcon clOralnge" icon="mobile-alt" />(+57) 311 811 1168</p>
                                        <p><FontAwesomeIcon className="cotactIcon clOralnge" icon="envelope" />bienvenido&#64;henleimd&#46;com</p>
                                    </div>
                                </Fade>

                                <div className="col-10 col-sm-5 col-md-4 col-lg-5 col-xl-4 justify-content-center text-center chats2">
                                    <div className="row">
                                        <Zoom cascade delay={200}>
                                            <div className="col-4 tl">
                                                <a href="https://wa.me/573134736826" target="_blank" rel="noopener noreferrer">
                                                    <Zoom delay={200}> <FontAwesomeIcon className="cotactIcon2" icon={['fab', 'whatsapp']} /></Zoom>
                                                </a>
                                            </div>
                                            <div className="col-4 tc">

                                                <span></span>

                                            </div>
                                            <div className="col-4 tr">
                                                <a href="https://fb.me/henleimd" target="_blank" rel="noopener noreferrer">
                                                    <Zoom delay={200}>  <FontAwesomeIcon className="cotactIcon2" icon={['fab', 'facebook-f']} /></Zoom>
                                                </a>
                                            </div>
                                            <div className="col-4 cl">
                                                <a href="https://m.me/henleimd" target="_blank" rel="noopener noreferrer">
                                                    <Zoom delay={200}><FontAwesomeIcon className="cotactIcon2" icon={['fab', 'facebook-messenger']} /></Zoom>
                                                </a>
                                            </div>
                                            <div className="col-4 cc">
                                                <a href="https://www.instagram.com/henleimd/" target="_blank" rel="noopener noreferrer">
                                                    <Zoom delay={200}>   <FontAwesomeIcon className="cotactIcon2" icon={['fab', 'instagram']} /></Zoom>
                                                </a>
                                            </div>
                                            <div className="col-4 cr">
                                                <span></span>

                                            </div>
                                            <div className="col-4 bl">
                                                <span></span>

                                            </div>
                                            <div className="col-4 bc">
                                                <span></span>

                                            </div>
                                            <div className="col-4 br">
                                                <a href="https://twitter.com/henleimd/" target="_blank" rel="noopener noreferrer">
                                                    <Zoom delay={200}> <FontAwesomeIcon className="cotactIcon2" icon={['fab', 'twitter']} /></Zoom>
                                                </a>
                                            </div>
                                        </Zoom>
                                    </div>

                                </div>


                                {/* <div className="col-6 justify-content-center text-center chats">
                                <p className="iconsChat">
                                    <a href="https://wa.me/573134736826" target="_blank" rel="noopener noreferrer">
                                         <FontAwesomeIcon className="cotactIcon" icon={['fab', 'whatsapp']} /> Envíanos un mensaje por WhatsApp 
                                    </a>
                                </p>
                                <p className="iconsChat">
                                    <a href="https://m.me/henleimd" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon className="cotactIcon" icon={['fab', 'facebook-messenger']} /> ¡Chatea con nosotros ahora por facebook!
                                    </a>
                                </p>

                                <p><FontAwesomeIcon className="cotactIcon clOralnge" icon="envelope" /> bienvenido&#64;henleimd&#46;com</p>
                            </div> */}
                            </div>


                            {/* <div className="row siguenos">
                            <div className="col-12 nopadding">
                                <h5 className="text-center">Síguenos</h5>
                            </div>
                            <div className="col iconos">
                                <a href="https://fb.me/henleimd" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
                                <a href="https://twitter.com/henleimd/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
                                <a href="https://www.instagram.com/henleimd/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
                            </div>
                        </div> */}
                        </div>
                        <div className="col-1"></div>
                    </div>
                </div>
            </section>
        </Fade>
    );
}

export default SectionContactChat;