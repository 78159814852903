import React, { Fragment } from 'react';
import './apps.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SectionContactChat from '../layouts/sectionContactChat/SectionContactChat';
import MetaTags from 'react-meta-tags';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';


const Apps = () => {

    window.scroll(0,0);

    const title = "HenLei MD - Aplicaciones Móviles";
    const description = "La calidad y la originalidad son nuestra prioridad, te invitamos a ver nuestras aplicaciones moviles y seas testigo de ello.";
    const keywords = "HenLei MD, Desarrollo de apps, desarrollo de aplicaciones,  diseño de aplicaciones, Agencia de desarrollo, diseño apps, creacion aplicaciones, aplicaciones hibridas, marketing de aplicaciones, agencia aplicaciones, agencia desarrollo de apps";
    const imgMeta = 'https://henleimd.com/multimedia/images/imgTags/apps.jpg';


    return (
        <Fragment>

            <MetaTags>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />

                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={imgMeta} />

                <meta property="og:title" content={title} />
                <meta property="og:url" content="https://henleimd.com/apps" />
                <meta property="og:image" content={imgMeta} />
                <meta property="og:description" content={description} />
                <meta property="og:site_name" content={title} />
                <link rel="canonical" href="https://henleimd.com/apps"/>
            </MetaTags>

            <section className="mainApps" id="mainApps">
                <Fade top>
                    <div className=" container-fluid head nopadding justify-content-center">
                        <div className="row opac">
                            <div className="col-12">
                                <Slide left delay={200}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="main-text">APLICACIONES MOVILES</h1>
                                        </div>
                                    </div>

                                    <div className="row justify-content-end">
                                        <div className="col-12 col-md-8 col-lg-7 col-xl-6">
                                            <h2>Usamos las mejores tecnologías del mercado para darte soluciones optimas y a la medida </h2>
                                        </div>
                                    </div>
                                </Slide>
                                <Zoom delay={200}>
                                    <div className="row justify-content-end">
                                        <div className="col-12 col-md-6">
                                            <Link className="btn" to="/trabajos">Conoce nuestro estilo</Link>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                    </div>
                </Fade>

                <div className="container-fluid devApps nopadding">

                    <div className="row nopadding">
                        <div className="col-12 nopadding">
                            <Zoom>
                                <h2 className="text-center main-text">Desarrollo de Aplicaciones Moviles</h2>
                            </Zoom>
                            <Zoom>
                                <h3 className="text-center sbtlt">Hechas a la medida y con herramientas de punta</h3>
                            </Zoom>
                        </div>
                    </div>

                    <div className="row justify-content-around wedo">
                        <div className="col-12 col-lg-5">
                            <Slide left>
                                <h3 className="wedotlt">Hacemos desarrollos para las principales plataformas</h3>
                                <div className="text-center">
                                    <FontAwesomeIcon className="cotactIcon2" icon={['fab', 'apple']} />
                                    <FontAwesomeIcon className="cotactIcon2" icon={['fab', 'android']} />
                                </div>
                                <p className="wedoP">Contamos con las herramientas, el conocimiento y el equipo necesarios para construir aplicaciones a la medida, adaptadas a cada una de las necesidades de nuestros clientes, basándonos en</p>
                            </Slide>
                        </div>
                        <Slide right>
                            <div className="col-12 col-sm-10 col-lg-6">
                                <div className="accordion md-accordion" id="appsMeds" role="tablist" aria-multiselectable="true">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne1">
                                            <a data-toggle="collapse" data-parent="#appsMeds" href="#collapseOne1" aria-expanded="true"
                                                aria-controls="collapseOne1">
                                                <h5 className="mb-0">
                                                    Experiencia de usuario
                                                    <FontAwesomeIcon icon="angle-down" className="rotate-icon" />
                                                </h5>
                                            </a>
                                        </div>

                                        <div id="collapseOne1" className="collapse show" role="tabpanel" aria-labelledby="headingOne1"
                                            data-parent="#appsMeds">
                                            <div className="card-body">
                                                <p>Lo que los usuarios perciban al interactuar con tu aplicación es lo que definirá si vuelven a ella o no, por tanto, nosotros partimos de las necesidades de los usuarios y lo que va a hacer tu aplicación para satisfacer dicha necesidad, y lo canalizamos dentro de los objetivos de tu negocio o proyecto.</p>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingTwo2">
                                            <a className="collapsed" data-toggle="collapse" data-parent="#appsMeds" href="#collapseTwo2"
                                                aria-expanded="false" aria-controls="collapseTwo2">
                                                <h5 className="mb-0">
                                                    Soluciones personalizadas
                                                <FontAwesomeIcon icon="angle-down" className="rotate-icon" />
                                                </h5>
                                            </a>
                                        </div>

                                        <div id="collapseTwo2" className="collapse" role="tabpanel" aria-labelledby="headingTwo2"
                                            data-parent="#appsMeds">
                                            <div className="card-body">
                                                <p>Nos apoyamos con los grandes proveedores de bases de datos en la nube como Google, adicional de la confianza que ofrece una compañía de gran escala, el trabajar con bases de datos en la nube nos va a permitir facilidad de cargas administrativas, costos reducidos, alta disponibilidad, infraestructura sólida, y no tendrás que preocuparte por dar mantenimiento o modificar configuraciones.</p>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingThree3">
                                            <a className="collapsed" data-toggle="collapse" data-parent="#appsMeds" href="#collapseThree3"
                                                aria-expanded="false" aria-controls="collapseThree3">
                                                <h5 className="mb-0">
                                                    Diseños intuitivos
                                                <FontAwesomeIcon icon="angle-down" className="rotate-icon" />
                                                </h5>
                                            </a>
                                        </div>

                                        <div id="collapseThree3" className="collapse" role="tabpanel" aria-labelledby="headingThree3"
                                            data-parent="#appsMeds">
                                            <div className="card-body">
                                                <p>Entre más sencilla de usar es una aplicación, más satisfacción sientes los usuarios con ellas y aumentan su uso, ya que ante una aplicación que les resulte compleja se pueden sentir frustrados y optan por eliminarla. Te ayudaremos a crear una aplicación que intuitiva para el usuario y acompañada de mucho material visual que intuitivamente lleve a tus usuarios a entenderla y manejarla a la perfección.</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingFour4">
                                            <a className="collapsed" data-toggle="collapse" data-parent="#appsMeds" href="#collapseFour4"
                                                aria-expanded="false" aria-controls="collapseFour4">
                                                <h5 className="mb-0">
                                                    Aplicaciones hibridas
                                                <FontAwesomeIcon icon="angle-down" className="rotate-icon" />
                                                </h5>
                                            </a>
                                        </div>

                                        <div id="collapseFour4" className="collapse" role="tabpanel" aria-labelledby="headingFour4"
                                            data-parent="#appsMeds">
                                            <div className="card-body">
                                                <p>El desarrollo de aplicaciones hibridas nos va a permitir construir una única aplicación que va a ser visible tanto para IOS como para Android, y usable en prácticamente cualquier dispositivo móvil, el hecho de construir una única aplicación te ayudara a ahorrar dinero en tener que construir apps por separado para cada sistema operativo y adicionalmente, el mantenimiento y/o actualizaciones serán más fáciles de manejar y menos costosos, plataformas como Facebook, Instagram o Uber están construidas con tecnologías de este tipo, lo que te puede dar una idea de la capacidad que podemos alcanzar.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingFive5">
                                            <a className="collapsed" data-toggle="collapse" data-parent="#appsMeds" href="#collapseFive5"
                                                aria-expanded="false" aria-controls="collapseFive5">
                                                <h5 className="mb-0">
                                                    Soportadas en la nube
                                                <FontAwesomeIcon icon="angle-down" className="rotate-icon" />
                                                </h5>
                                            </a>
                                        </div>

                                        <div id="collapseFive5" className="collapse" role="tabpanel" aria-labelledby="headingFive5"
                                            data-parent="#appsMeds">
                                            <div className="card-body">
                                                <p>Nos apoyamos con los grandes proveedores de bases de datos en la nube como Google, adicional de la confianza que ofrece una compañía de gran escala, el trabajar con bases de datos en la nube nos va a permitir facilidad de cargas administrativas, costos reducidos, alta disponibilidad, infraestructura sólida, y no tendrás que preocuparte por dar mantenimiento o modificar configuraciones.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    </div>
                    <Bounce left cascade>
                        <div className="row justify-content-around text-center brands-dev">
                            <div className="col">
                                <FontAwesomeIcon className="icnBrand" icon={['fab', 'google']} />
                            </div>
                            <div className="col">
                                <FontAwesomeIcon className="icnBrand" icon={['fab', 'amazon']} />
                            </div>
                            <div className="col">
                                <FontAwesomeIcon className="icnBrand" icon={['fab', 'android']} />
                            </div>
                            <div className="col">
                                <FontAwesomeIcon className="icnBrand" icon={['fab', 'apple']} />
                            </div>
                            <div className="col">
                                <FontAwesomeIcon className="icnBrand" icon={['fab', 'react']} />
                            </div>
                        </div>
                    </Bounce>

                </div>

                <div className="d-none .d-sm-block container img-phone">
                    <Bounce bottom>
                        <div className="row justify-content-around">
                            <div className="col-1"></div>
                            <div className="col-sm-7 col-lg-5 text-center">
                                <div className="contBox text-center">
                                    <div className="glass"></div>
                                    <div className="glass"></div>
                                </div>
                            </div>
                            <div className="d-none col-lg-5 text-center">
                                <div className="contBox text-center">
                                    <div className="glass"></div>
                                    <div className="glass"></div>
                                </div>
                            </div>
                        </div>
                    </Bounce>
                </div>


                <div className="container-fluid mkApps">
                    <div className="row nopadding justify-content-around">
                        <div className="d-none col-lg-5"></div>
                        <div className="col-11 col-lg-7 col-xl-6 nopadding">
                            <Zoom> <h2 className="text-center main-text">Apps Marketing</h2></Zoom>
                            <Zoom><h3 className="text-center sbtlt">Haz que tu aplicación resalte entre millones</h3></Zoom>
                        </div>
                    </div>


                    <div className="row mkAppsTxt justify-content-around">

                        <div className="col-5 imgmkapps"></div>

                        <div className="col-11 col-lg-7 col-xl-6">
                            <Flip> <p>El promocionar una aplicación es casi tan importante como su desarrollo, porque finalmente el objetivo es que tu aplicación sea usada por la mayor cantidad de usuarios posibles, y para lograr esto debes competir en un mercado de cientos de miles de apps. Aquí es donde entramos nosotros para ayudarte a crear una estrategia para llegar al publico interesado en tu aplicación,te ayudaremos a crear tus propios canales para dar a conocer tu aplicación y a generar publicidad a través de los principales medios de comunicación digitales de acuerdo con tu tipo de aplicación y el mercado objetivo que buscas alcanzar, incluidas las mismas tiendas de aplicaciones. </p>
                                <p>Y por supuesto aplicaremos nuestras estrategias de Marketing y Social Media de tal forma que podamos cuantificar los resultados y así cumplir todos los objetivos planteados.</p></Flip>
                        </div>
                    </div>
                </div>
            </section>
            <SectionContactChat />
        </Fragment>
    );
}

export default Apps;