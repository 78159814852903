import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './workList.scss';

const WorkList = ({ workItem }) => {

    return (
        <Fragment>

            <div className=" col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="singleWork">
                    <div className="front">
                        <h3 className="titleWorkFront text-center"> {workItem.categoryName}</h3>
                        <img className="img-fluid" src={process.env.PUBLIC_URL + workItem.minImageRoute} alt={`Screenshot del trabajo de ${workItem.categoryName} realizado para ${workItem.clientName}, dentro de nuestra area de ${workItem.categoryName}`} />

                    </div>
                    <div className="back">
                        <div className="contentCard">
                            <h3>{workItem.categoryName}<br /><span>{workItem.clientName}</span></h3>
                            <img className="img-fluid" src={process.env.PUBLIC_URL + workItem.logoImageRoute} alt={`Logo de la empresa ${workItem.clientName}`} />
                            <div className="btnSeeWork">
                                <Link to={`/detalleTrabajo/${workItem.amicalUrl}`}>Ver Proyecto</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );
}

export default WorkList;