import React, { Fragment } from 'react';
import './banner.scss';
import { Link } from 'react-router-dom';


const Banner = () => {
    return (
        <Fragment>

            <div className="container-fluid banner">

                <div id="carouselHomePage" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselHomePage" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselHomePage" data-slide-to="1"></li>
                        <li data-target="#carouselHomePage" data-slide-to="2"></li>
                        <li data-target="#carouselHomePage" data-slide-to="3"></li>
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active slide1">

                            <div className="row justify-content-left nopadding">
                                <div className="col-8 col-sm-7 col-md-5 slide1-left">
                                    <h2 className="text-left"><span>Digitaliza</span> tu Negocio y abre tus Oportunidades</h2>
                                    <h3 className="text-left text-md-center"><Link className="btnhh" to='desarrollo'>Conoce cómo</Link></h3>
                                    {/* <h3 className="text-left">Llega a nuevos clientes con Campañas Desde Redes sociales y Google</h3> */}
                                    {/* <h4 className="text-left">Te ayudamos con una estrategia digital de resultados</h4> */}
                                </div>
                                <div className="col-5 slide1-right d-none d-sm-block">
                                    {/* <img src={bner1} alt="¿Cómo expandir tu negocio?" /> */}
                                </div>
                            </div>

                        </div>

                        <div className="carousel-item slide2">

                            <div className="row justify-content-center nopadding">

                                <div className="col-6 d-none d-sm-block">
                                    {/* <img className="img-fluid" src={bner2} alt="Ideas de Marketing y Desarrollo" /> */}
                                </div>

                                <div className="col-10 col-sm-7 col-md-6 slide2-center">
                                    <h2 className="text-right text-md-center"><span>Comparte</span> Tus ideas con el Mundo</h2>

                                    <h3 className="text-right text-md-center"><Link className="btnhh" to='contacto'>Contáctanos</Link></h3>
                                    {/*<h4 className="text-right text-md-center">En HenLei NO solo hacemos Webs, queremos ser Tu aliado estratégico</h4> */}
                                </div>

                            </div>
                        </div>
                        <div className="carousel-item slide3">
                            <div className="row justify-content-end nopadding">

                                <div className="col-10 col-lg-7 slide2-center">
                                    <h2 className="text-right text-lg-center">Nuevas formas de <span>Relacionarte</span> con tus clientes</h2>
                                    <h3 className="text-right text-md-center"><Link className="btnhh" to='socialmedia'>Conoce cómo</Link></h3>
                                    {/* <h3 className="text-right text-lg-center">Desde redes sociales puedes posicionar tu marca</h3> */}
                                    {/* <h4 className="text-right text-lg-center">Nosotros te podemos ayudar a crear contenidos de valor</h4> */}
                                </div>

                            </div>
                        </div>

                        <div className="carousel-item slide4">
                            <div className="row justify-content-center nopadding">
                                <div className="col-10 col-sm-9 bgc">
                                    <h2 className="text-center">Acercate un poco más al mundo Digital</h2>
                                    <h3 className="text-center">Te invitamos a visitar nuestro Blog</h3>
                                    <h4 className="text-center">Tenemos contenidos que te pueden ayudar a mejorar tu estrategia</h4>
                                </div>
                            </div>

                        </div>
                    </div>

                    <a className="carousel-control-prev" href="#carouselHomePage" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselHomePage" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>


                </div>

            </div>
        </Fragment>
    );
}

export default Banner;