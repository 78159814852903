import React, { Fragment } from 'react';
import './home.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Banner from '../layouts/banner/Banner';
import SectionCotactForm from '../layouts/sectionContactForm/SectionContactForm';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import MetaTags from 'react-meta-tags';
import Carousel from 'react-multi-carousel';

const Home = () => {

    window.scroll(0, 0);
    const title = "HenLei MD - Agencia de Marketing Digital y Desarrollo Web";
    const description = "Somos una agencia de marketing digital y desarrollo web, creamos campañas de publicidad online, desarrollamos webs a la medida, y estrategias de social media y posicionamiento.";
    const keywords = "HenLei MD, Desarrollo Web, Marketing Digital, posicionamiento web, branding, estrategia digital, diseño web, Agencia de publicidad, Agencia de desarrollo, marketing online, agencia digital, publicidad web, diseño paginas web, creacion paginas web";
    const imgMeta = 'https://henleimd.com/multimedia/images/imgTags/home.jpg';

    let video;

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    if (window.innerWidth > 991) {
        video = <video id="vidbg" autoPlay loop autobuffer="true"><source src={process.env.PUBLIC_URL + '/multimedia/videos/workvideo.mp4'} type="video/mp4" /></video>
    } else {
        video = null;
    }

    return (
        <Fragment>

            <MetaTags>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />

                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={imgMeta} />

                <meta property="og:title" content={title} />
                <meta property="og:url" content="https://henleimd.com/" />
                <meta property="og:image" content={imgMeta} />
                <meta property="og:description" content={description} />
                <meta property="og:site_name" content={title} />
                <link rel="canonical" href="https://henleimd.com/" />
            </MetaTags>

            <header id="header">
                <div className="bgblack">
                    <div className="d-none d-lg-block">

                        {video}
                        {/* <video id="vidbg" autoPlay loop autobuffer="true" muted>
                            <source src={citybg} type="video/mp4" />
                        </video> */}
                    </div>

                    <div className="container-fluid">
                        <Slide left>
                            <div className="row justify-content-left der">
                                <div className="col d-flex align-items-center justify-content-end">
                                    <h1 className="text-right">SOMOS LA AGENCIA <br />DE MARKETING Y DESARROLLO <br />QUE NECESITAS COMO ALIADA</h1>
                                </div>
                            </div>
                        </Slide>
                        <Zoom>
                            <div className="row justify-content-between pasion">
                                <div className="col d-flex align-items-center justify-content-center">
                                    <h2 className="text-center">COMPARTE NUESTRA PASIÓN <br />POR LA EXCELENCIA</h2>
                                    <Link to="/trabajos"><FontAwesomeIcon icon="angle-double-right" className="go" /></Link>
                                </div>
                            </div>
                        </Zoom>
                    </div>
                </div>
            </header>

            <Zoom>
                <div className="container-fluid sols">
                    <div className="row title">
                        <div className="col-12 text-center">
                            <Zoom delay={200}>
                                <h2>CONOCE LAS SOLUCIONES QUE TENEMOS PARA TI</h2>
                            </Zoom>
                        </div>
                    </div>

                    <div className="row justify-content-around">
                        <Zoom delay={200}>
                            <div className="col-12 col-md-6 col-lg-3 nopadding">
                                <div className="imagen imgDev">
                                    <div className="info devweb">
                                        <Fade top delay={600}><h3 className="headline align-middle"><span>{"<"}</span> Páginas Web <span>{">"}</span></h3></Fade>
                                        <p className="descripcion">Si quieres contar con un sitio que te permita llegar a tus clientes, lo diseñamos y desarrollamos justo a la medida de tu empresa y adaptable a cualquier dispositivo.</p>
                                        <div className="vermas text-center">
                                            <Link to="/desarrollo" className="verbtn blcl"> Ver </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Zoom>

                        <Zoom delay={300}>
                            <div className="col-12 col-md-6 col-lg-3 nopadding">
                                <div className="imagen imgSoMe">

                                    <div className="info socmed">
                                        <Fade bottom delay={700}><h3 className="headline align-middle"><span>#</span> Social Media <span>#</span> </h3></Fade>
                                        <p className="descripcion">Para que más personas alrededor del mundo conozcan tu negocio, nos encargamos de crear y administrar tus redes sociales, posicionando tu marca en el mercado.</p>
                                        <div className="vermas text-center">
                                            <Link to="socialmedia" className="verbtn prpcl"> Ver </Link>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </Zoom>
                        <Zoom delay={400}>
                            <div className="col-12 col-md-6 col-lg-3 nopadding">

                                <div className="col imagen imgMark">

                                    <div className="info mkting">
                                        <Fade top delay={800}><h3 className="headline align-middle"><span>$</span> Marketing <span>$</span> </h3></Fade>
                                        <p className="descripcion">A través de campañas originales dirigidas a buscadores y redes sociales te podemos llevar a ti y a tu negocio al siguiente nivel.</p>
                                        <div className="vermas text-center">
                                            <Link to="/marketing" className="verbtn rdcl"> Ver </Link>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </Zoom>
                        <Zoom delay={500}>
                            <div className="col-12 col-md-6 col-lg-3 nopadding">
                                <div className="col imagen imgApps">

                                    <div className="info devapps">
                                        <Fade bottom delay={900}><h3 className="headline align-middle"><span>{'{'}</span> Apps <span>{'}'}</span></h3></Fade>
                                        <p className="descripcion">Con aplicaciones móviles estarás en la capacidad de administrar eventos, compartir contenidos y generar un contacto directo con tus clientes.</p>
                                        <div className="vermas text-center">
                                            <Link to="/apps" className="verbtn ylcl"> Ver </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Zoom>
                    </div>
                </div>


            </Zoom>
            <Banner />

            <Fade>
                <div className="container" id="brochure">
                    <div className="row">

                        <div className="col-12 col-md-6">
                            <Fade left>
                                <h3 className="tlh3">Conoce nuestro <br /> <strong>Brochure</strong></h3>
                            </Fade>
                            <Zoom>
                                <p>Nuestras propuestas de trabajo están enfocado a la expansión de marcas, fidelización de clientes y generación de nueva audiencia</p>
                            </Zoom>
                        </div>

                        <div className="col-12 col-md-6 btnBrochure text-center">
                            <div className="row">
                                <Fade top>
                                    <div className="col-12 text-center">
                                        <FontAwesomeIcon className="" icon="arrow-down"></FontAwesomeIcon>
                                    </div>
                                </Fade>
                                <div className="col-12">
                                    <Bounce bottom>
                                        <a className="btnhh" href={process.env.PUBLIC_URL + '/multimedia/docs/BROCHURE HenLei MD.pdf'} download="BROCHURE HenLei MD.pdf">
                                            Descargar Brochure <FontAwesomeIcon className="" icon="file-download"></FontAwesomeIcon>
                                        </a>
                                    </Bounce>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </Fade>

            <SectionCotactForm />

            <div className="container" id="weare">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <h3 className="wearetitle">Somos <strong>HenLei MD</strong></h3>
                    </div>
                    <Flip top cascade>
                        <div className="col-6 col-4 col-md-3 col-lg-2 text-center weareCont">
                            <b>|</b><FontAwesomeIcon className="weareIcon" icon="paint-brush"></FontAwesomeIcon><b>|</b>
                            <p className="mb-0"><strong>Somos</strong><br />creativos</p>
                        </div>


                        <div className="col-6 col-4 col-md-3 col-lg-2 text-center weareCont">
                            <b>|</b> <FontAwesomeIcon className="weareIcon" icon="broadcast-tower"></FontAwesomeIcon> <b>|</b>
                            <p className="mb-0"><strong>Somos</strong><br />Comunicativos</p>
                        </div>


                        <div className="col-6 col-4 col-md-3 col-lg-2 text-center weareCont">
                            <b>|</b> <FontAwesomeIcon className="weareIcon" icon="shapes"></FontAwesomeIcon> <b>|</b>
                            <p className="mb-0"><strong>Somos</strong><br />Adaptables</p>
                        </div>


                        <div className="col-6 col-4 col-md-3 col-lg-2 text-center weareCont">
                            <b>|</b> <FontAwesomeIcon className="weareIcon" icon="cart-plus"></FontAwesomeIcon> <b>|</b>
                            <p className="mb-0"><strong>Somos</strong><br />Digitales</p>
                        </div>

                        <div className="col-6 col-4 col-md-3 col-lg-2 text-center weareCont">
                            <b>|</b> <FontAwesomeIcon className="weareIcon" icon="fire"></FontAwesomeIcon> <b>|</b>
                            <p className="mb-0"><strong>Somos</strong><br />Apasionados</p>
                        </div>

                        <div className="col-6 col-4 col-md-3 col-lg-2 text-center weareCont">
                            <b>|</b> <FontAwesomeIcon className="weareIcon" icon="code-branch"></FontAwesomeIcon> <b>|</b>
                            <p className="mb-0"><strong>Somos</strong><br />Diferentes</p>
                        </div>
                    </Flip>
                </div>
            </div>

           
            <Zoom>
                <div className="container-fluid nopadding">
                    <section id="pilars">
                        <div className="container-fluid nopadding">
                            <div className="row justify-content-center nopadding">
                                <Fade top delay={400}>
                                    <div className="col-12 text-center colcol2 nopadding">
                                        <h2>¿Cómo lo hacemos?</h2>
                                    </div>
                                </Fade>
                                <Fade top delay={400}>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-3 colcol nopadding">
                                        <div className="contenido nopadding">
                                            <h2>Definición</h2>
                                            <div className="contentTxt nopadding">
                                                <h3>Definición</h3>
                                                <p>Una vez te contactes con nosotros y decidas iniciar tu proyecto, iniciaremos un proceso en el cual escucharemos las necesidades de tu negocio y tus expectativas, recibirás toda la asesoría necesaria, en esta etapa plantearemos una propuesta y un plan de acción integral para generar el mayor valor posible para tu negocio y tus clientes.</p>
                                            </div>
                                        </div>
                                        <div className="bg bg1"></div>
                                    </div>

                                </Fade>
                                <Fade bottom delay={600}>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-3 colcol nopadding">
                                        <div className="contenido nopadding">
                                            <h2>Creación</h2>
                                            <div className="contentTxt nopadding">
                                                <h3>Creación</h3>
                                                <p>Posteriormente desglosaremos el plan de acción en tareas, ya que son medibles y controlables en resultados y tiempos, esto nos permitirá hacer seguimiento del avance del proyecto y también flexibilizar el proceso, ya que podremos realizar algunas modificaciones sobre la marcha de acuerdo con nuevas ideas o necesidades que surjan. </p>
                                            </div>
                                        </div>
                                        <div className="bg bg1"></div>
                                    </div>
                                </Fade>

                                <Fade top delay={800}>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-3 colcol nopadding">
                                        <div className="contenido nopadding">
                                            <h2>Publicación</h2>
                                            <div className="contentTxt nopadding">
                                                <h3>Publicación</h3>
                                                <p>Cuando ya todo esté listo, te brindaremos una muestra previa del resultado del proceso de creación, para dar un último vistazo a todo, una vez estemos de acuerdo con todos los detalles del proyecto, haremos el lanzamiento, y pondremos en marcha las estrategias de medición de resultados.</p>
                                            </div>
                                        </div>
                                        <div className="bg bg1"></div>
                                    </div>
                                </Fade>
                                <Fade bottom delay={1000}>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-3 colcol nopadding">
                                        <div className="contenido nopadding">
                                            <h2>Seguimiento</h2>
                                            <div className="contentTxt nopadding">
                                                <h3>Seguimiento</h3>
                                                <p>En palabras simples, todo lo que se puede medir se puede controlar y mejorar, por lo que, una vez publicado, pondremos a tu disposición herramientas y/o informes para que puedas medir y hacer seguimiento de forma precisa a tu proyecto, lo que te permitirá tomar decisiones estratégicas sobre tu negocio.</p>
                                            </div>
                                        </div>
                                        <div className="bg bg1"></div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </section>
                </div>


            </Zoom>

            <Fragment>
                <div className="container" id="ourClients">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h3 className="ourClientstitle">Nuestros <strong>Clientes</strong></h3>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" id="ourClients">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <Carousel
                                additionalTransfrom={0}
                                arrows={false}
                                autoPlay={true}
                                autoPlaySpeed={2000}
                                centerMode={true}
                                className=""
                                customTransition="all 1s linear"
                                draggable={false}
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                minimumTouchDrag={80}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={responsive}
                                showDots={false}
                                swipeable={false}
                                slidesToSlide={2}
                                transitionDuration={2000}
                            >
                                <img className="imgClient" src={process.env.PUBLIC_URL + "/multimedia/images/clientesLogo/LOGO ARTEL.png"} alt="something" />
                                <img className="imgClient" src={process.env.PUBLIC_URL + "/multimedia/images/clientesLogo/LOGO DIMOFER.png"} alt="something" />
                                <img className="imgClient" src={process.env.PUBLIC_URL + "/multimedia/images/clientesLogo/LOGO GLOBAL.png"} alt="something" />
                                <img className="imgClient" src={process.env.PUBLIC_URL + "/multimedia/images/clientesLogo/LOGO INVARQ.png"} alt="something" />
                                <img className="imgClient" src={process.env.PUBLIC_URL + "/multimedia/images/clientesLogo/LOGO JORGEMORA.png"} alt="something" />
                                <img className="imgClient" src={process.env.PUBLIC_URL + "/multimedia/images/clientesLogo/LOGO QUALITY FOODS.png"} alt="something" />
                                <img className="imgClient" src={process.env.PUBLIC_URL + "/multimedia/images/clientesLogo/LOGO FNDC.png"} alt="something" />

                            </Carousel>
                        </div>
                    </div>
                </div>
            </Fragment>


            {/* <div className="container">
                <div className="row us2">
                    <div className="col-12 nopadding">

                        <div className="row nomargin nopadding">
                            <div className="col text-center nopadding">
                                <Zoom>
                                    <p><FontAwesomeIcon className="color" icon="angle-double-left" /> Nuestra estrategia está basada en crear soluciones integrales que cubran cada elemento necesario para llegar a resultados excepcionales. <FontAwesomeIcon className="color" icon="angle-double-right" /></p>
                                </Zoom>
                                <Zoom delay={400}>
                                    <p><FontAwesomeIcon className="color" icon="angle-double-left" /> Ofrecemos opciones adaptables al tamaño de nuestros clientes y sus proyectos, conectando ideas con el resto del mundo y haciendo que estas sean notorias para todos los posibles clientes. <FontAwesomeIcon className="color" icon="angle-double-right" /></p>
                                </Zoom>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </Fragment>
    );
}

export default Home;