import React, { useState, Fragment } from "react";
import "./contacto.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Errors from "../layouts/error/Errors";
import { ContactUser } from "../../models/ContactUser";
import { endpoint_service_ins_form } from "../../enviroment/enviroment";
import Axios from "axios";
import Swal from "sweetalert2";
import MetaTags from "react-meta-tags";

const Contacto = () => {
    const title = "HenLei MD - Contactanos";
    const description =
        "Dejanos un mensaje, con esto empezaremos a construir el futuro de tu empresa en el mundo digital y te ayudaremos a mostrar al mundo todo lo que tu negocio tiene para dar.";
    const keywords =
        "HenLei MD, Desarrollo Web, Marketing Digital, posicionamiento web, branding, estrategia digital, diseño web, Agencia de publicidad, Agencia de desarrollo, marketing online, agencia digital, publicidad web, diseño paginas web, creacion paginas web";
    const imgMeta = "https://henleimd.com/multimedia/images/imgTags/contact.jpg";

    const [nombre, saveNombre] = useState("");
    const [email, saveEmail] = useState("");
    const [telefono, saveTelefono] = useState("");
    const [mensaje, saveMensaje] = useState("");
    const [messageError, saveMessageError] = useState([]);
    const [loader, saveLoader] = useState(null);
    const [disab, saveDisab] = useState(false);

    const [error, saveError] = useState(false);

    const sendForm = async (e) => {
        e.preventDefault();
        let txtErr = [];
        // Check name field
        if (
            !/^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}[A-Za-zÁÉÍÓÚñáéíóúÑ]+[\s]*)+$/i.test(nombre) ||
            nombre === ""
        ) {
            txtErr.push("Por favor ingresa un nombre valido");
        }
        if (nombre.length > 100) {
            txtErr.push(
                "Por favor procura que el nombre que ingresas no supere los 100 caracteres"
            );
        }

        // Check email field
        if (
            !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,4})$/i.test(
                email
            ) ||
            email === ""
        ) {
            txtErr.push("Por favor ingresa un correo valido");
        }

        // Check phone field
        if (!/^^([0-9()+ ]{7,})$/i.test(telefono) || telefono === "") {
            txtErr.push("Por favor ingresa un número de teléfono valido");
        }
        if (telefono.length > 30) {
            txtErr.push("Por favor ingresa un número de teléfono valido");
        }

        // Check mensaje field
        if (
            !/^([A-Za-zÁÉÍÓÚñáéíóúÑ0-9()+,.!"$][\s]*)+$/i.test(mensaje) ||
            mensaje === ""
        ) {
            txtErr.push(
                "Por favor en tu mensaje no agregues caracteres especiales (*#$%^&();)"
            );
        }

        if (mensaje.length > 1000) {
            txtErr.push(
                "Por favor procura que el mensaje que ingresas no supere los 1000 caracteres"
            );
        }

        if (txtErr.length > 0) {
            saveMessageError(txtErr);
            saveError(true);
            return;
        }

        saveError(false);

        try {

            saveLoader(<FontAwesomeIcon icon="circle-notch" className="spinnerContact" />);
            saveDisab(true);

            const URL = endpoint_service_ins_form;

            let dataForm = new ContactUser();
            dataForm.nombre = nombre;
            dataForm.email = email;
            dataForm.telefono = telefono;
            dataForm.mensaje = mensaje;

            const result = await Axios.post(URL, dataForm, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            saveNombre("");
            saveEmail("");
            saveTelefono("");
            saveMensaje("");
            saveMessageError([]);

            if (result.status === 201) {
                Swal.fire({
                    title: '<b class="titleSwal">' + result.data.message + "</b>",
                    html: "Pronto nos cotactaremos contigo",
                    icon: "success",
                    confirmButtonText: "¡ Ok !",
                    focusConfirm: false,
                });
            } else {
                Swal.fire({
                    title: '<b class="titleSwal">Error</b>',
                    text:
                        "Algo ha salido mal por favor refresca la página y vuelve a intentar",
                    icon: "error",
                    confirmButtonText: "¡ Vale !",
                    focusConfirm: false,
                });
            }
        } catch (error) {
            Swal.fire({
                title: '<b class="titleSwal">Error</b>',
                text:
                    "Algo ha salido mal por favor refresca la página y vuelve a intentar",
                icon: "error",
                confirmButtonText: "¡ Vale !",
                focusConfirm: false,
            });
        }
        saveLoader(null);
        saveDisab(false);

    };

    return (
        <Fragment>
            <MetaTags>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />

                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={imgMeta} />

                <meta property="og:title" content={title} />
                <meta property="og:url" content="https://henleimd.com/contacto" />
                <meta property="og:image" content={imgMeta} />
                <meta property="og:description" content={description} />
                <meta property="og:site_name" content={title} />
                <link rel="canonical" href="https://henleimd.com/contacto" />
            </MetaTags>
            <section className="mainContact" id="mainContact">
                <Fade top>
                    <div className="container-fluid nopadding">
                        <div className="head nopadding">
                            <div className="row opac nopadding">
                                <Slide left delay={200}>
                                    <div className="col-12 text-center nopadding">
                                        <h1>Contactanos</h1>
                                        <h2>Podemos hacer grandes cosas por ti</h2>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </Fade>

                <div className="container-fluid nopadding">
                    <Fade bottom>
                        <div className="row formulario">
                            <div className="col-12">
                                <div className="row justify-content-around">
                                    <div className="col-12 col-lg-4">
                                        <form onSubmit={sendForm}>
                                            <Fade left delay={400}>
                                                <Fragment>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text">
                                                                    <FontAwesomeIcon
                                                                        className="frmIcon"
                                                                        icon="user"
                                                                    ></FontAwesomeIcon>
                                                                </div>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="nameSectContact"
                                                                id="nameSectContact"
                                                                placeholder="Nombre"
                                                                required
                                                                value={nombre}
                                                                onChange={(e) => saveNombre(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text">
                                                                    <FontAwesomeIcon
                                                                        className="frmIcon"
                                                                        icon="at"
                                                                    ></FontAwesomeIcon>
                                                                </div>
                                                            </div>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                name="emailSectContact"
                                                                id="emailSectContact"
                                                                placeholder="Correo electrónico"
                                                                required
                                                                value={email}
                                                                onChange={(e) => saveEmail(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text">
                                                                    <FontAwesomeIcon
                                                                        className="frmIcon"
                                                                        icon="phone"
                                                                    ></FontAwesomeIcon>
                                                                </div>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="telefonoSectContact"
                                                                id="telefonoSectContact"
                                                                placeholder="Teléfono"
                                                                required
                                                                value={telefono}
                                                                onChange={(e) => saveTelefono(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text">
                                                                    <FontAwesomeIcon
                                                                        className="frmIcon"
                                                                        icon="comment-dots"
                                                                    ></FontAwesomeIcon>
                                                                </div>
                                                            </div>
                                                            <textarea
                                                                name="mensajeSectContact"
                                                                id="mensajeSectContact"
                                                                placeholder="Mensaje"
                                                                className="form-control mensaje"
                                                                required
                                                                value={mensaje}
                                                                onChange={(e) => saveMensaje(e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    <p className="terminos">
                                                        Enviando aceptas los{" "}
                                                        <Link to="/terminos">términos y condiciones</Link>
                                                    </p>

                                                    <div className="col-12 sendbtn">
                                                        <div className="col-12 text-center">
                                                            <input
                                                                type="submit"
                                                                className="btn btnhh"
                                                                value="Envíar"
                                                                disabled={disab}
                                                            ></input>
                                                            {
                                                                loader
                                                            }
                                                        </div>
                                                    </div>

                                                    {error ? <Errors message={messageError} /> : null}
                                                </Fragment>
                                            </Fade>
                                        </form>
                                        <div
                                            className="alert alert-danger errores"
                                            id="errores"
                                        ></div>
                                    </div>
                                    <Fade right delay={400}>
                                        <div className="col-12 col-lg-4">
                                            <Zoom delay={500}>
                                                <h3 className="linestyle">Por Redes</h3>
                                            </Zoom>

                                            <div className="redes">
                                                <Zoom delay={600}>
                                                    <div className="col iconos text-center">
                                                        <a
                                                            className="facebook"
                                                            href="https://fb.me/henleimd"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <FontAwesomeIcon
                                                                className="cotactIcon2"
                                                                icon={["fab", "facebook-f"]}
                                                            />
                                                        </a>
                                                        <a
                                                            className="twitter"
                                                            href="https://twitter.com/henleimd/"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <FontAwesomeIcon
                                                                className="cotactIcon2"
                                                                icon={["fab", "twitter"]}
                                                            />
                                                        </a>
                                                        <a
                                                            className="instagram"
                                                            href="https://www.instagram.com/henleimd/"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <FontAwesomeIcon
                                                                className="cotactIcon2"
                                                                icon={["fab", "instagram"]}
                                                            />
                                                        </a>
                                                    </div>
                                                </Zoom>
                                            </div>

                                            <Zoom delay={500}>
                                                <h3 className="linestylechats">click chats</h3>
                                            </Zoom>
                                            <Zoom delay={600}>
                                                <div className="row redes">
                                                    <div className="col iconos text-center">
                                                        <a
                                                            className="whatsapp"
                                                            href="https://wa.me/573134736826"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <FontAwesomeIcon
                                                                className="cotactIcon2"
                                                                icon={["fab", "whatsapp"]}
                                                            />
                                                        </a>
                                                        <a
                                                            className="messenger"
                                                            href="https://m.me/henleimd"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <FontAwesomeIcon
                                                                className="cotactIcon2"
                                                                icon={["fab", "facebook-messenger"]}
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </Zoom>
                                            <Zoom bottom delay={600}>
                                                <p className="infochat text-center">
                                                    Da click en el icono y chatea al instante con nosotros
                        </p>
                                            </Zoom>
                                        </div>
                                    </Fade>
                                </div>
                                <Slide bottom delay={200}>
                                    <div className="row telefonos justify-content-around">
                                        <div className="col-8">
                                            <hr />
                                            <div className="row justify-content-around text-center">
                                                <div className="col-12 col-md-6 col-xl-3">
                                                    <p>
                                                        <FontAwesomeIcon icon="phone" /> (+571) 789 3044
                          </p>
                                                </div>
                                                <div className="col-12 col-md-6 col-xl-3">
                                                    <p>
                                                        <FontAwesomeIcon icon="mobile-alt" /> (+57) 311 811
                            1168
                          </p>
                                                </div>
                                                <div className="col-12 col-md-6 col-xl-3">
                                                    <p>
                                                        <FontAwesomeIcon icon="mobile-alt" /> (+57) 313 473
                            6826
                          </p>
                                                </div>
                                                <div className="col-12 col-md-6 col-xl-3">
                                                    <p>
                                                        <FontAwesomeIcon icon="envelope" />{" "}
                            bienvenido@henleimd.com
                          </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
        </Fragment>
    );
};

export default Contacto;
