
// LOCAL

// const endpoint = "http://localhost:8708";

// export const endpoint_service_ins_email = endpoint + "/emailBlog/email/";
// export const endpoint_service_works = endpoint + "/portafolio/";
// export const endpoint_service_works_detail = endpoint + "/trabajos/uno/";

// export const endpoint_service_works_similar = endpoint + "/trabajos/categoria/";


// export const endpoint_service_ins_form = endpoint + "/contacForm/contact/";

// PROD


/* const endpoint = "https://hlmd-bk.herokuapp.com/";

export const endpoint_service_ins_email = endpoint + "/emailBlog/email/";
export const endpoint_service_works = endpoint + "/portafolio/";
export const endpoint_service_works_detail = endpoint + "/trabajos/uno/";

export const endpoint_service_works_similar = endpoint + "/trabajos/categoria/";


export const endpoint_service_ins_form = endpoint + "/contacForm/contact/";
 */

 // LARAVEL


//  dev

// const endpoint = "http://127.0.0.1:8000/api";

// export const endpoint_service_ins_email = endpoint + "/emailBlog";
// export const endpoint_service_works = endpoint + "/portfolio";
// export const endpoint_service_works_detail = endpoint + "/detailWorks/one/";

// export const endpoint_service_works_similar = endpoint + "/detailWorks/";


// export const endpoint_service_ins_form = endpoint + "/contactForm";

//  prod
 const endpoint = "https://hlapi.henleimd.com/api";

 export const endpoint_service_ins_email = endpoint + "/emailBlog";
 export const endpoint_service_works = endpoint + "/portfolio";
 export const endpoint_service_works_detail = endpoint + "/detailWorks/one/";
 
 export const endpoint_service_works_similar = endpoint + "/detailWorks/";
 
 
 export const endpoint_service_ins_form = endpoint + "/contactForm";


 

