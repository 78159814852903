import React, { useState, useEffect, Fragment } from "react";
// import { Link } from 'react-router-dom';
import axios from "axios";
import { endpoint_service_works } from "../../enviroment/enviroment";
import "./works.scss";
import WorkList from "./worksList/WorkList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import Zoom from "react-reveal/Zoom";
import Bounce from "react-reveal/Bounce";
import MetaTags from "react-meta-tags";
import Loading from "../layouts/loading/Loading";
import comingSoon from "../../multimedia/images/enConstruccion/coming-soon.jpg";

const Works = () => {
  window.scroll(0, 0);

  const title = "Trabajos HenLei MD";
  const description =
    "En HenLei MD hacemos todo con la mejor calidad y mejor que decirlo es mostrarlo, estos son los proyectos que hemos realizado para que lo veas con tus propios ojos.";
  const keywords =
    "HenLei MD, Desarrollo Web, Marketing Digital, posicionamiento web, branding, estrategia digital, diseño web, Agencia de publicidad, Agencia de desarrollo, marketing online, agencia digital, publicidad web, diseño paginas web, creacion paginas web";
  const imgMeta = "https://henleimd.com/multimedia/images/imgTags/works.jpg";

  const [works, saveWorks] = useState([]);
  const [categoryWork, saveCategoryWork] = useState("");
  const [loader, saveLoader] = useState(true);

  useEffect(() => {
    if (loader) {
      const consultarAPI = async () => {
        const URL = endpoint_service_works;
        const result = await axios.get(URL + categoryWork);
        if (result.status === 201) {
          saveWorks(result.data.data);
        } else {
          saveWorks([]);
        }

        saveLoader(false);
      };
      try {
        consultarAPI();
      } catch (error) {
        saveLoader(false);
      }
    }
  }, [loader, categoryWork]);

  return (
    <Fragment>
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imgMeta} />

        <meta property="og:title" content={title} />
        <meta property="og:url" content="https://henleimd.com/trabajos" />
        <meta property="og:image" content={imgMeta} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content={title} />
        <link rel="canonical" href="https://henleimd.com/trabajos"/>

      </MetaTags>

      <section className="mainTrabajos" id="mainTrabajos">
        <div className="container-fluid nopadding">
          <Fade top>
            <div className="head nopadding">
              <div className="row opac">
                <Slide left delay={200}>
                  <div className="col-12 text-center">
                    <h1>Nuestro Portafolio</h1>
                  </div>
                </Slide>
              </div>
            </div>
          </Fade>

          <div className="container menuWorks">
            <div className="row">
              <div className="col-12">
                <ul className="nav nav-pills nav-fill" id="toggleMenuHL">
                  <Bounce bottom cascade>
                    <li className="nav-item">
                      <h2>
                        <button
                          className="nav-link"
                          id="todos"
                          type="button"
                          onClick={() => {
                            saveCategoryWork("");
                            saveLoader(true);
                          }}
                        >
                          <FontAwesomeIcon icon="boxes" /> TODOS
                        </button>
                      </h2>
                    </li>

                    <li className="nav-item">
                      <h2>
                        <button
                          className="nav-link"
                          id="pagsweb"
                          type="button"
                          onClick={() => {
                            saveCategoryWork("/1");
                            saveLoader(true);
                          }}
                        >
                          <FontAwesomeIcon icon="code" /> PÁGINAS WEB
                        </button>
                      </h2>
                    </li>
                    <li className="nav-item">
                      <h2>
                        <button
                          className="nav-link "
                          id="social"
                          type="button"
                          onClick={() => {
                            saveCategoryWork("/3");
                            saveLoader(true);
                          }}
                        >
                          <FontAwesomeIcon icon="comments" /> SOCIAL MEDIA
                        </button>
                      </h2>
                    </li>
                    <li className="nav-item">
                      <h2>
                        <button
                          className="nav-link"
                          id="marketing"
                          type="button"
                          onClick={() => {
                            saveCategoryWork("/2");
                            saveLoader(true);
                          }}
                        >
                          <FontAwesomeIcon icon="chart-line" /> MARKETING
                        </button>
                      </h2>
                    </li>
                    <li className="nav-item">
                      <h2>
                        <button
                          className="nav-link"
                          id="apps"
                          type="button"
                          onClick={() => {
                            saveCategoryWork("/4");
                            saveLoader(true);
                          }}
                        >
                          <FontAwesomeIcon icon="mobile-alt" /> APPS
                        </button>
                      </h2>
                    </li>
                  </Bounce>
                </ul>
              </div>
            </div>
          </div>
          <div className="container-fluid worksItems">
            <Zoom>
              <div className="row">
                {loader ? (
                  <Loading />
                ) : works.length > 0 ? (
                  works.map((workItem) => (
                    <WorkList workItem={workItem} key={workItem.id} />
                  ))
                ) : (
                  <div className="soon">
                    <h3 className="text-center">
                      Estamos trabajando en una <span>App</span> que organizara
                      tu vida
                    </h3>
                    <h4 className="text-center">
                      {" "}
                      <span>¡</span>Esperala Muy Pronto <span>!</span>
                    </h4>
                    <div className="imgContainer">
                      <img src={comingSoon} alt="coming soon" />
                    </div>
                  </div>
                )}
              </div>
            </Zoom>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Works;
