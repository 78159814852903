import React, { Fragment } from 'react';
import './marketing.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SectionContactChat from '../layouts/sectionContactChat/SectionContactChat';
import googletab from '../../multimedia/images/marketing/g_adds/googletab.jpg';
import staticstab from '../../multimedia/images/marketing/emailmk/staticstab.jpg';
import cmsAdd from '../../multimedia/images/marketing/contenidosmk/cmsAdd.jpg';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import MetaTags from 'react-meta-tags';


const Marketing = () => {

    window.scroll(0,0);
    const title = "HenLei MD - Marketing Digital";
	const description = "La calidad y la originalidad son nuestra prioridad, te invitamos a ver nuestros trabajos de marketing digital y seas testigo de ello.";
	const keywords = "HenLei MD, Marketing Digital, branding, estrategia digital, email marketing, campañas en redes sociales, administracion redes sociales, SEO, SEM, marketing de contenidos, Facebook adds, Google AdWords, Agencia de publicidad, marketing online, agencia digital, publicidad web, posicionamiento en buscadores, posicionamiento en Google, publicidad en redes, creacion de contenidos, marketing B2B, marketing B2C, marketing C2C, marketing C2B, SEO, SEM";
	const imgMeta = 'https://henleimd.com/multimedia/images/imgTags/marketing.jpg';

    return (
        <Fragment>
            <MetaTags>
				<title>{title}</title>
				<meta name="description" content={description}/>
				<meta name="keywords" content={keywords} />

				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				<meta name="twitter:image" content={imgMeta} />

				<meta property="og:title" content={title} />
				<meta property="og:url" content="https://henleimd.com/marketing" />
				<meta property="og:image" content={imgMeta} />
				<meta property="og:description" content={description} />
				<meta property="og:site_name" content={title} />
                <link rel="canonical" href="https://henleimd.com/marketing"/>
			</MetaTags>

            <section className="mainMd" id="mainMd">
                <Fade top>
                    <div className=" container-fluid head nopadding justify-content-center">
                        <div className="row opac">
                            <div className="col-12">
                                <Slide left delay={200}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="main-text">MARKETING DIGITAL</h1>
                                        </div>
                                    </div>

                                    <div className="row justify-content-end">
                                        <div className="col-12 col-md-8 col-lg-7 col-xl-6">
                                            <h2>Usamos las mejores tecnologías del mercado para darte soluciones optimas y a la medida </h2>
                                        </div>
                                    </div>
                                </Slide>
                                <Zoom delay={200}>
                                    <div className="row justify-content-end">
                                        <div className="col-12 col-sm-6">
                                            <Link className="btn" to="/trabajos">Conoce nuestro estilo</Link>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                    </div>
                </Fade>


                <div className="container-fluid SsCamp nopadding">
                    <div className="row nopadding">
                        <div className="col-12 nopadding">
                            <Zoom> <h2 className="text-center main-text">Campañas Eficientes</h2></Zoom>
                        </div>
                    </div>
                    <div className="campannas">
                        <Slide left>
                            <div className="campannasAdwords">
                                <div className="row justify-content-around nopadding adwordsC">
                                    <div className="d-none d-md-block col-4 col-xl-3">
                                        <img className="img-fluid img-thumbnail" src={googletab} alt="Campañas de Google AdWords" />
                                    </div>

                                    <div className="col-12 col-md-8 col-lg-7 text">
                                        <div className="row">
                                            <div className="col-12">
                                                <h2 className="adjust text-center titlesub">Google AdWords</h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="text-center">Con Google AdWords podrás poner a tu negocio en los primeros resultados del principal buscador del mundo, todas las páginas web compiten constantemente por llegar a estas posiciones de distintas formas, nosotros te ayudamos a crear e implementar una estrategia que lleve a tu página web a los primeros puestos. ¡Imagina que cuando busquen lo que vendes seas el primero en aparecer!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                        <Slide right>
                            <div className="campannasEmail">
                                <div className="row justify-content-around nopadding emailmk">
                                    <div className="col-12 col-md-8 col-lg-7 text">
                                        <div className="row">
                                            <div className="col-12">
                                                <h2 className="adjust text-center titlesub">Email Marketing</h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="text-center">A través del email marketing podrás mantenerte en contacto con tus clientes para hacerles llegar noticias, novedades, nuevos posts de tu blog, información de tus productos, encuestas, entre muchas otras cosas y lo mejor de todo es que es posible hacerlo de una forma medible con la que podrás segmentar a todos tus potenciales clientes y convertirlos en clientes reales.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-none d-md-block col-4 col-xl-3">
                                        <img className="img-fluid img-thumbnail" src={staticstab} alt="Campañas de Email Marketing" />
                                    </div>
                                </div>
                            </div>
                        </Slide>
                        <Slide left>

                            <div className="campannasCont">
                                <div className="row justify-content-around nopadding contsmk">
                                    <div className="d-none d-md-block col-4 col-xl-3">
                                        <img className="img-fluid img-thumbnail" src={cmsAdd} alt="Marketing de Contenidos" />
                                    </div>

                                    <div className="col-12 col-md-8 col-lg-7 text">
                                        <div className="row">
                                            <div className="col-12">
                                                <h2 className="adjust text-center titlesub">Marketing de Contenidos</h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="text-center">Para atraer personas a tu página web es necesario poner contenido de valor para el usuario y que por supuesto esté relacionado con tu negocio. Cada día más personas buscan respuestas a través de internet, en HenLei podemos ayudarte a generar contenidos que respondan a las necesidades de tu público objetivo y que por supuesto los impulse a convertirse en nuevos clientes.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>

                    </div>
                </div>

                <div className="container containerSeo">

                    <div className="row titulos">
                        <div className="col-12 nopadding">
                            <Zoom><h2 className="main-text2 text-center">SEO Y SEM</h2></Zoom>
                        </div>
                        <div className="col-12">
                            <Zoom> <h3 className="text-center ">Posicionamiento y Diagnosticos de página web para SEO y SEM</h3></Zoom>
                        </div>
                    </div>

                    <Flip left cascade>
                        <div className="row justify-content-around">

                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="box bx1">
                                    <div className="content">
                                        <h2>01</h2>
                                        <h3>Auditoría</h3>
                                        <p>Si ya tienes un sitio Web, nosotros te ayudamos a revisar su contenido, etiquetas, enlaces y todo aquello que logra un mejor posicionamiento en los navegadores web de manera orgánica mientras escuchamos todo sobre las expectativas que tienes respecto de tu página Web.</p>
                                        <FontAwesomeIcon icon="external-link-alt" className="icono" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="box bx2">
                                    <div className="content">
                                        <h2>02</h2>
                                        <h3>Creación de Objetivos</h3>
                                        <p>Con base en la revisión realizada nosotros aterrizamos todas tus ideas en objetivos y te orientamos sobre el proceso a realizar mientras nos encargamos de revisar todo el panorama para generar la mejor propuesta de publicidad en Google y en otros buscadores de acuerdo con las necesidades de tu negocio.</p>
                                        <FontAwesomeIcon icon="bullseye" className="icono" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="box bx3">
                                    <div className="content">
                                        <h2>03</h2>
                                        <h3>Planificación</h3>
                                        <p>LUna vez determinemos todos los objetivos que queremos lograr con tu Página Web para hacer crecer tu negocio, ahora entramos a la etapa de planificación en donde nosotros generamos una propuesta con el plan de acción a seguir para alcanzar los objetivos que quieres alcanzar para tu empresa.</p>
                                        <FontAwesomeIcon icon="compass" className="icono" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="box bx4">
                                    <div className="content">
                                        <h2>04</h2>
                                        <h3>Implementación</h3>
                                        <p>En esta parte lanzamos las campañas de Adds para los buscadores en los que hayamos decidido ejecutar el proyecto, con el apoyo de herramientas de generación de Tags en links para poder realizar el control.</p>
                                        <FontAwesomeIcon icon="check-double" className="icono" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="box bx5">
                                    <div className="content">
                                        <h2>05</h2>
                                        <h3>  Seguimiento y Medición</h3>
                                        <p>En HenLei MD, realizamos el seguimiento sobre la campaña y te generamos reportes sencillos pero muy completos para que sepas de que forma estamos cumpliendo los objetivos de que definimos para la campaña.</p>
                                        <FontAwesomeIcon icon="chart-bar" className="icono" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="box bx6">
                                    <div className="content">
                                        <h2>06</h2>
                                        <h3>Kaizen</h3>
                                        <p>Kaizen en pocas palabras significa mejora continua, y es una filosofía para nuestros proyectos, con base en las mediciones que obtenemos generamos nuevas propuestas que harán crecer a tu empresa</p>
                                        <FontAwesomeIcon icon="pencil-ruler" className="icono" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Flip>
                </div>


            </section>



            <SectionContactChat />

        </Fragment>
    );
}

export default Marketing;