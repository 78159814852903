import React from 'react';
import { Link } from 'react-router-dom';
import './404.scss';

const Err404 = () => {
    return (

        <section className="err404" id="err404">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1>¡OOPS!</h1>
                        <h2>La página que buscas no existe</h2>
                        <p>
                            <span className="four1">4</span><span className="cero">0</span><span className="four2">4</span>
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 text-center">
                        <Link to="/">Regresar</Link>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Err404;