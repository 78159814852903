import React, { useState } from 'react';
import './sectionContactForm.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Bounce from 'react-reveal/Bounce';
import Rotate from 'react-reveal/Rotate';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Swal from 'sweetalert2';
import Axios from 'axios';
import Errors from '../../layouts/error/Errors';
import { ContactUser } from '../../../models/ContactUser';
import { endpoint_service_ins_form } from '../../../enviroment/enviroment';

const SectionCotact = () => {

    const [nombre, saveNombre] = useState('');
    const [email, saveEmail] = useState('');
    const [telefono, saveTelefono] = useState('');
    const [mensaje, saveMensaje] = useState('');
    const [messageError, saveMessageError] = useState([]);
    const [loader, saveLoader] = useState(null);
    const [disab, saveDisab] = useState(false);

    const [error, saveError] = useState(false);

    const sendForm = async e => {

        e.preventDefault();
        let txtErr = [];
        // Check name field
        if (!(/^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}[A-Za-zÁÉÍÓÚñáéíóúÑ]+[\s]*)+$/i.test(nombre)) || nombre === '') {
            txtErr.push('Por favor ingresa un nombre valido');

        }
        if (nombre.length > 100) {
            txtErr.push('Por favor procura que el nombre que ingresas no supere los 100 caracteres');
        }


        // Check email field 
        if (!(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,4})$/i.test(email)) || email === '') {
            txtErr.push('Por favor ingresa un correo valido');
        }

        // Check phone field 
        if (!(/^^([0-9()+ ]{7,})$/i.test(telefono)) || telefono === '') {
            txtErr.push('Por favor ingresa un número de teléfono valido');

        }
        if (telefono.length > 30) {
            txtErr.push('Por favor ingresa un número de teléfono valido');

        }

        // Check mensaje field 
        if (!(/^([A-Za-zÁÉÍÓÚñáéíóúÑ0-9()+,.!"$][\s]*)+$/i.test(mensaje)) || mensaje === '') {
            txtErr.push('Por favor en tu mensaje no agregues caracteres especiales (*#$%^&();)');
        }

        if (mensaje.length > 1000) {
            txtErr.push('Por favor procura que el mensaje que ingresas no supere los 1000 caracteres');
        }

        if (txtErr.length > 0) {
            saveMessageError(txtErr);
            saveError(true);
            return;
        }

        saveError(false);

        try {
            
            saveLoader(<FontAwesomeIcon icon="circle-notch" className="spinnerLoading"/>);
            saveDisab(true);
            const URL = endpoint_service_ins_form;

            let dataForm = new ContactUser();
            dataForm.nombre = nombre;
            dataForm.email = email;
            dataForm.telefono = telefono;
            dataForm.mensaje = mensaje;


            const result = await Axios.post(URL, dataForm, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            saveNombre('');
            saveEmail('');
            saveTelefono('');
            saveMensaje('');
            saveMessageError([]);


            if (result.status === 201) {

                Swal.fire({
                    title: '<b class="titleSwal">' + result.data.message + "</b>",
                    html: 'Pronto nos cotactaremos contigo',
                    icon: 'success',
                    confirmButtonText: '¡ Ok !',
                    focusConfirm: false
                })
            } else {
                Swal.fire({
                    title: '<b class="titleSwal">Error</b>',
                    text: 'Algo ha salido mal por favor refresca la página y vuelve a intentar',
                    icon: 'error',
                    confirmButtonText: '¡ Vale !',
                    focusConfirm: false
                })
            }
        } catch (error) {
            Swal.fire({
                title: '<b class="titleSwal">Error</b>',
                text: 'Algo ha salido mal por favor refresca la página y vuelve a intentar',
                icon: 'error',
                confirmButtonText: '¡ Vale !',
                focusConfirm: false
            })
        }

        saveLoader(null);
        saveDisab(false);

    }


    return (
        <Zoom>
            <section className="contacto" id="contactFormSect">
                <div className="container-fluid bgblack">

                    <div className="row justify-content-left">
                        <div className="col-1"></div>
                        <div className="col-12 col-lg-10 col-xl-5 formulario">
                            <Bounce top delay={400}>
                                <h4 className="text-center">Dejanos un mensaje</h4>
                            </Bounce>
                            <form onSubmit={sendForm}>
                                <div className="row justify-content-around">
                                    <div className="col-12 col-sm-10 col-md-6">
                                        <Rotate top left delay={600}>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon className="frmIcon" icon="user"></FontAwesomeIcon>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                        className="form-control"
                                                        name="nameSectContact"
                                                        id="nameSectContact"
                                                        placeholder="Nombre"
                                                        required
                                                        value={nombre}
                                                        onChange={(e) => saveNombre(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </Rotate>

                                        <Rotate top right delay={800}>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon className="frmIcon" icon="at"></FontAwesomeIcon>
                                                        </div>
                                                    </div>
                                                    <input type="email"
                                                        className="form-control"
                                                        name="emailSectContact"
                                                        id="emailSectContact"
                                                        placeholder="Correo electrónico"
                                                        value={email}
                                                        onChange={(e) => saveEmail(e.target.value)}
                                                        required />
                                                </div>
                                            </div>
                                        </Rotate>

                                        <Rotate bottom left delay={1000}>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon className="frmIcon" icon="phone"></FontAwesomeIcon>
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                        className="form-control"
                                                        name="telefonoSectContact"
                                                        id="telefonoSectContact"
                                                        placeholder="Teléfono"
                                                        value={telefono}
                                                        onChange={(e) => saveTelefono(e.target.value)}
                                                        required />
                                                </div>
                                            </div>
                                        </Rotate>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-6">
                                        <Rotate top right delay={1200}>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon className="frmIcon" icon="comment-dots"></FontAwesomeIcon>
                                                        </div>
                                                    </div>
                                                    <textarea name="mensajeSectContact" id="mensajeSectContact"
                                                        placeholder="Mensaje"
                                                        className="form-control mensaje"
                                                        required
                                                        value={mensaje}
                                                        onChange={(e) => saveMensaje(e.target.value)}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </Rotate>
                                        <Zoom delay={1400}>
                                            <p className="terminos">Enviando aceptas los <Link to="/terminos">términos y condiciones</Link></p>
                                        </Zoom>
                                    </div>
                                    <Fade bottom delay={1800}>
                                        <div className="col-10 col-md-12 sendbtn">
                                            <div className="col-12 col-sm-10 text-center">
                                                <input type="submit" className="btn btnhh" value="Envíar" disabled={disab}></input>
                                                {
                                                    loader                                                    
                                                }
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                {(error) ? <Errors message={messageError} /> : null}
                            </form>
                            <div className="alert alert-danger errores" id="errores">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Zoom>
    );
}

export default SectionCotact;