import React, { Fragment } from 'react';
import './webDev.scss';
import { Link } from 'react-router-dom';
import SectionContactChat from '../layouts/sectionContactChat/SectionContactChat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import emailAcounts from '../../multimedia/images/develop/productos/emailAccounts.jpg';
import hosting from '../../multimedia/images/develop/productos/hosting.jpg';
import landingPage from '../../multimedia/images/develop/productos/landingPage.jpg';
import adminWeb from '../../multimedia/images/develop/productos/adminWeb.jpg';
import forms from '../../multimedia/images/develop/productos/forms.jpg';
import chats from '../../multimedia/images/develop/productos/chats.jpg';
import salesMan from '../../multimedia/images/develop/ecommerce/salesMan.png';
import pcCoffe from '../../multimedia/images/develop/ecommerce/pcCoffe.png';
import creditCard from '../../multimedia/images/develop/ecommerce/creditCard.png';
import MetaTags from 'react-meta-tags';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';

const WebDev = () => {

    window.scroll(0, 0);

    const title = "HenLei MD - Desarrollo Web";
    const description = "La calidad y la originalidad son nuestra prioridad, te invitamos a ver nuestros trabajos de desarrollo y seas testigo de ello.";
    const keywords = "HenLei MD, Desarrollo Web, posicionamiento web,  diseño web, Agencia de desarrollo, diseño paginas web, creacion paginas web, paginas responsive, diseño responsive, paginas adaptables a dispositivos moviles, aplicaciones web, ecommerce, tiendas online, tiendas en línea, cuentas de correo, dominio, hosting, correo corporativo, landing page, formularios web, B2B, B2C, C2C, C2B, SEO, SEM";
    const imgMeta = 'https://henleimd.com/multimedia/images/imgTags/webdev.jpg';

    return (
        <Fragment>

            <MetaTags>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />

                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={imgMeta} />

                <meta property="og:title" content={title} />
                <meta property="og:url" content="https://henleimd.com/desarrollo" />
                <meta property="og:image" content={imgMeta} />
                <meta property="og:description" content={description} />
                <meta property="og:site_name" content={title} />
                <link rel="canonical" href="https://henleimd.com/desarrollo"/>

            </MetaTags>

            <section className="maindw" id="maindw">
                <Fade top>
                    <div className=" container-fluid head nopadding">
                        <div className="row opac">
                            <div className="col-12">
                                <Slide left delay={200}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="main-text">DESARROLLO WEB</h1>
                                        </div>
                                    </div>

                                    <div className="row justify-content-end">
                                        <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                                            <h2>Usamos las mejores tecnologías del mercado para darte soluciones optimas y a la medida </h2>
                                        </div>
                                    </div>
                                </Slide>
                                <Zoom delay={200}>
                                    <div className="row justify-content-end">
                                        <div className="col-12 col-sm-6">
                                            <Link className="btn" to="/trabajos">Conoce nuestro estilo</Link>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                    </div>
                </Fade>


                <div className="container-fluid">
                    <div className="row justify-content-end">
                        <div className="col-12">
                            <Zoom><h2 className="title text-center">Diseño de Páginas Web</h2></Zoom>
                        </div>
                    </div>
                </div>

                <div className="container-fluid disweb">
                    <div className="container">
                        <Zoom cascade>
                            <div className="row justify-content-around">
                                <div className="col-10 col-md-9 col-lg-3 caract up">
                                    <Flip left delay={400}><h3>Diseños Originales</h3></Flip>
                                    <div className="text-center icon">
                                        <Flip left delay={400}><FontAwesomeIcon icon="vector-square" /></Flip>
                                    </div>
                                    <Flip right delay={400}><p className="text-center">Los diseños que creamos y los contenidos que ponemos siempre son una combinación única y atractiva.</p></Flip>
                                </div>

                                <div className="col-10 col-md-5 col-lg-3 caract up">
                                    <h3>UX / UI</h3>
                                    <div className="text-center icon">
                                        <FontAwesomeIcon icon="users" />
                                    </div>
                                    <p className="text-center">La experiencia de usuario y en la usabilidad de tu web es imprescindible ante usuarios cada día más exigentes.</p>
                                </div>
                                <div className="col-10 col-md-5 col-lg-3 caract up">
                                    <h3>CMS</h3>
                                    <div className="text-center icon">
                                        <FontAwesomeIcon className="cotactIcon2" icon={['fab', 'wordpress']} />
                                        <FontAwesomeIcon className="cotactIcon2" icon={['fab', 'joomla']} />
                                    </div>
                                    <p className="text-center">Nuestros diseños están basados en los gestores de contenidos más populares y confiables del mundo.</p>

                                </div>

                            </div>
                        </Zoom>

                        <Zoom cascade>
                            <div className="row justify-content-around">
                                <div className="col-10 col-md-9 col-lg-3 caract down">
                                    <h3>Adaptables</h3>
                                    <div className="text-center icon">
                                        <FontAwesomeIcon icon="mobile-alt" />
                                    </div>
                                    <p className="text-center">Tu página Web será visible desde cualquier dispositivo, desde un celular hasta una pantalla de televisor.</p>

                                </div>
                                <div className="col-10 col-md-5 col-lg-3 caract down">
                                    <h3>Blogs</h3>
                                    <div className="text-center icon">
                                        <FontAwesomeIcon icon="align-center" />
                                    </div>
                                    <p className="text-center">La creación de contenidos permitirá a las personas acercase a tu web, nosotros te ayudamos a crear un Blog para lograrlo.</p>

                                </div>
                                <div className="col-10 col-md-5 col-lg-3 caract down">
                                    <h3>Seguridad</h3>
                                    <div className="text-center icon">
                                        <FontAwesomeIcon icon="user-secret" />
                                    </div>
                                    <p className="text-center">La seguridad es cada vez más importante, en HenLei nos apropiamos de esto y nos apoyamos en las mejores herramientas.</p>

                                </div>
                            </div>
                        </Zoom>
                    </div>
                </div>

                <div className="container-fluid message">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3 lateral order-2 order-lg-1">
                            <Zoom><h4 className="text-center">Ponte en Línea</h4></Zoom>
                            <Zoom><p className="text-center">En la actualidad muchas empresas quiebran por no estar en línea, y las que mejores rendimientos tienen es porque lo estan</p></Zoom>
                        </div>
                        <Flip>
                            <div className="col-1 d-none d-lg-block order-lg-2">
                                <div className="corange">
                                </div>
                                <br />
                                <div className="cblue">

                                </div>
                            </div>
                        </Flip>

                        <div className="col-12 col-lg-4 center order-1 order-lg-3">
                            <Zoom delay={200}><p className="text-center">Internet es la forma más rápida y segura de hacer crecer tu negocio, y nosotros como expertos te ayudamos</p></Zoom>

                        </div>
                        <Flip>
                            <div className="col-1 d-none d-lg-block order-lg-4">
                                <div className="corange">

                                </div>
                                <br />
                                <div className="cblue">

                                </div>
                            </div>
                        </Flip>
                        <div className="col-12 col-md-6 col-lg-3 lateral order-3 order-lg-5">
                            <Zoom delay={400}><h4 className="text-center">Ahora Todo es Web</h4></Zoom>
                            <Zoom delay={400}><p className="text-center">Las nuevas generaciones buscan todo por medio de internet, es el momento de llegar a ellos.</p></Zoom>
                        </div>
                    </div>
                </div>


                <div className="container-fluid multsols">
                    <div className="row mulsoltitle">
                        <div className="col-12">
                            <Zoom delay={400}><h2 className="text-center main-text">Múltiples soluciones para ti</h2></Zoom>
                        </div>
                    </div>

                    <Slide right>
                        <div className="row justify-content-end">
                            <div className="col-lg-11 col-xl-10 obsder">
                                <div className="borde">
                                    <div className="row align-items-center  justify-content-end">
                                        <div className="col-9 col-sm-7 col-md-6 col-lg-5 col-xl-4">
                                            <img className="img-fluid imgsols" src={emailAcounts} alt="Cuenta de correo electrónico" />
                                        </div>
                                        <div className="col-12 col-lg-7 col-xl-6">
                                            <h3 className="text-right text-lg-left">Cuentas de Correo</h3>

                                            <p className="text-right text-lg-left">Cuentas de correo corporativas que dan mucho profesionalismo a tu marca y que te ayudaran a separar todo lo que respecta de tu negocio de acuerdo con tu grupo de trabajo y las áreas a las que pertenezcan.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>


                    <Slide left>
                        <div className="row justify-content-start">
                            <div className="col-lg-11 col-xl-10 obsder">
                                <div className="borde2">
                                    <div className="row align-items-center">
                                        <div className="col-lg-7 col-xl-6 order-2 order-lg-1">
                                            <h3>Hosting y dominio</h3>

                                            <p>Para cualquier página web es necesario contar con un dominio que es como el nombre, y el hosting es aquel lugar donde toda la información asociada a tu web como el código y el contenido se almacenan.</p>
                                        </div>

                                        <div className="col-9 col-sm-7 col-md-6 col-lg-5 col-xl-4 order-1 order-lg-2">
                                            <img className="img-fluid imgsols" src={hosting} alt="Creación de Hosting y Dominio" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    <Slide right>
                        <div className="row justify-content-end">
                            <div className="col-lg-11 col-xl-10 obsder">
                                <div className="borde">
                                    <div className="row align-items-center  justify-content-end">
                                        <div className="col-9 col-sm-7 col-md-6 col-lg-5 col-xl-4">
                                            <img className="img-fluid imgsols" src={landingPage} alt="Landing Pages para aumentar" />
                                        </div>
                                        <div className="col-12 col-lg-7 col-xl-6">
                                            <h3 className="text-right text-lg-left">Landing Page</h3>

                                            <p className="text-right text-lg-left">Páginas especializadas desde las cuales puedes anunciar los nuevos lanzamientos de tu empresa o apoyar campañas para atraer más clientes a tu negocio apoyadas por un gestor de bases de datos.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>
                    <Slide left>
                        <div className="row justify-content-start">
                            <div className="col-lg-11 col-xl-10 obsder">
                                <div className="borde2">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-lg-7 col-xl-6 order-2 order-lg-1">
                                            <h3>Administración de página web</h3>

                                            <p>Nosotros te podemos ayudar a administrar tu página web actualizando las últimas novedades y generando nuevos contenidos que permitan atraer nuevos clientes fijando siempre como meta el buen posicionamiento en buscadores.</p>
                                        </div>

                                        <div className="col-9 col-sm-7 col-md-6 col-lg-5 col-xl-4 order-1 order-lg-2">
                                            <img className="img-fluid imgsols" src={adminWeb} alt="Administración de Páginas Web" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>
                    <Slide right>
                        <div className="row justify-content-end">
                            <div className="col-lg-11 col-xl-10 obsder">
                                <div className="borde">
                                    <div className="row align-items-center justify-content-end">
                                        <div className="col-9 col-sm-7 col-md-6 col-lg-5 col-xl-4">
                                            <img className="img-fluid imgsols" src={forms} alt="Creación de formularios para aumentar la base de clientes" />
                                        </div>
                                        <div className="col-12 col-lg-7 col-xl-6">
                                            <h3 className="text-right text-lg-left">Formularios</h3>

                                            <p className="text-right text-lg-left">A través de formularios podrás recopilar datos de personas que visitan tu web y tus clientes que podrás usar para enviarles información que resulte de interés para ellos y los motive a realizar comprar nuevos productos.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    <Slide left>
                        <div className="row justify-content-start">
                            <div className="col-lg-11 col-xl-10 obsder">
                                <div className="borde2">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-lg-7 col-xl-6 order-2 order-lg-1">
                                            <h3>Chats</h3>

                                            <p>Cada día los clientes son más exigentes y piden respuestas en tiempo real antes de tomar una decisión de compra, un sistema de chat en tu página web podrá ayudarles a disipar la indecisión a confiar en tu marca y crear un vínculo. </p>
                                        </div>

                                        <div className="col-9 col-sm-7 col-md-6 col-lg-5 col-xl-4 order-1 order-lg-2">
                                            <img className="img-fluid imgsols" src={chats} alt="Creación de sistemas de chast" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>
                </div>

                <div className="container-fluid contEcommerce">


                    <div className="row">
                        <div className="col-12">
                            <Zoom><h2 className="text-center main-text">Ecommerce</h2></Zoom>
                        </div>
                    </div>

                    <Zoom cascade>
                        <div className="row justify-content-around">
                            <div className="box col-12 col-md-8 col-lg-4">
                                <div className="imgBx">
                                    <img src={salesMan} alt="Hombre de ventas, Tiendas para venta de Servicios" />
                                </div>
                                <div className="content">
                                    <h3>Somos Especialistas en</h3>
                                    <ul>
                                        <li>Tiendas para venta de Servicios</li>
                                        <li>Tiendas para venta de Productos físicos</li>
                                        <li>B2B (Business-to-Business)</li>
                                        <li>B2C (Business-to-Consumer)</li>
                                        <li>C2C (Consumer-to-Consumer)</li>
                                        <li>C2B (Consumer-to-Business)</li>
                                        <li>Entre otros</li>
                                    </ul>

                                </div>
                            </div>

                            <div className="box col-12 col-md-8 col-lg-4">
                                <div className="imgBx">
                                    <img src={pcCoffe} alt="Computador con taza de cafe, Creación y administración de catalogo" />
                                </div>
                                <div className="content">
                                    <h3>Te ofrecemos herramientas para</h3>
                                    <ul>
                                        <li>Creación y administración de catalogo</li>
                                        <li>Posicionamiento SEO y SEM</li>
                                        <li>Administración de contenidos</li>
                                        <li>Categorizar los productos</li>
                                        <li>Administrar costos adicionales (impuestos, costos de envío etc.)</li>
                                        <li>Poner productos en Oferta</li>
                                        <li>Entre otras</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="box col-12 col-md-8 col-lg-4">
                                <div className="imgBx">
                                    <img src={creditCard} alt="Tarjeta de crédito, pago en línea" />
                                </div>
                                <div className="content">
                                    <h3>Además tu tienda tendrá</h3>
                                    <ul>
                                        <li>Sistema de gestión de contenidos</li>
                                        <li>Adaptabilidad a móviles</li>
                                        <li>Carrito de compras</li>
                                        <li>Formularios</li>
                                        <li>Soporte para variaciones sobre los productos (talla, color, genero, etc.)</li>
                                        <li>Conexión con pasarelas de pagos</li>
                                        <li>Entre otros</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Zoom>
                </div>
            </section>

            <SectionContactChat />

        </Fragment>
    );
}

export default WebDev;