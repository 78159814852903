import React from 'react';
import './loading.scss';

const Loading = () => {
    return (
        <div id="loader">
            <div className="loader">
            </div>
        </div>
    );
}

export default Loading;