import React, { useState, useEffect, Fragment } from 'react';
import './workDetail.scss';
import { endpoint_service_works_detail, endpoint_service_works_similar } from '../../../enviroment/enviroment';
import Axios from 'axios';
import { DetalleTrabajo } from '../../../models/DetalleTrabajo';
import { withRouter, Link } from 'react-router-dom';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import MetaTags from 'react-meta-tags';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const WorkDetail = ({ history, workID }) => {

    window.scroll(0, 0);

    const [detailWork, saveDetailWork] = useState(new DetalleTrabajo());
    const [similarWorks, saveSimilaWorks] = useState([]);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };


    useEffect(() => {

        const consultarAPI = async () => {
            const URL = endpoint_service_works_detail;
            const result = await Axios.get(URL + workID);
            if(result.status === 201){
                saveDetailWork(result.data.data[0]);

                const URL_SIMILAR = endpoint_service_works_similar;
                const resultSimilar = await Axios.get(URL_SIMILAR + result.data.data[0].idCategoryProduct);

               

                if (resultSimilar.status === 201) {
                    const worksFiltered = resultSimilar.data.data.filter(resultF => resultF.id !== result.data.data[0].id);
                    saveSimilaWorks(worksFiltered);
                }
                
            } else {
                history.push('/404');
            }        
        }

        consultarAPI();
    }, [workID, history]);

    const splitData = (rutas) => {
        return rutas.split('|');
    }

    let arrImgs = [];
    if (detailWork.productImages) {
        arrImgs = splitData(detailWork.productImages);
    }

    return (

        <Fragment>
            <MetaTags>
                <title>{detailWork.titleAtr}</title>
                <meta name="description" content={detailWork.metaDesc} />
                <meta name="keywords" content={`${detailWork.metaKeyword}, ${detailWork.projectTitle}`} />
                <meta name="twitter:title" content={detailWork.titleAtr} />
                <meta name="twitter:description" content={detailWork.metaDesc} />
                <meta name="twitter:image" content={`https://henleimd.com${arrImgs[0]}`} />
                <meta property="og:title" content={detailWork.titleAtr} />
                <meta property="og:url" content={`https://henleimd.com/detalletrabajo/${detailWork.amicalUrl}`} />
                <meta property="og:image" content={`https://henleimd.com${arrImgs[0]}`} />
                <meta property="og:description" content={detailWork.metaDesc} />
                <meta property="og:site_name" content={detailWork.titleAtr} />
                <link rel="canonical" href={`https://henleimd.com/detalletrabajo/${detailWork.amicalUrl}`}/>
            </MetaTags>
            <section className="mainDetalleTrabajos" id="mainDetalleWorks">

                <Fade top>
                    <div className="container-fluid nopadding">
                        <div className={`head nopadding hd${detailWork.idCategoryProduct}`}>
                            <div className="row opac">
                                <Slide left delay={200}>
                                    <div className="col-12 text-center">
                                        <h1>{detailWork.workTitle}</h1>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </Fade>

                <div className="container-fluid nopadding">

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <Zoom><h2 className="main-text text-center">{detailWork.projectTitle}</h2></Zoom>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Flip><p className="description text-justify">{detailWork.description}</p></Flip>
                            </div>
                        </div>
                    </div>
                    <Fade bottom>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 galeriaProyecto">
                                    <Carousel
                                        additionalTransfrom={0}
                                        arrows
                                        autoPlaySpeed={3000}
                                        containerClass="container-with-dots"
                                        draggable
                                        focusOnSelect={false}
                                        infinite
                                        keyBoardControl
                                        minimumTouchDrag={80}
                                        renderButtonGroupOutside={false}
                                        renderDotsOutside={false}
                                        responsive={responsive}
                                        showDots={false}
                                        swipeable
                                        centerMode={true}
                                    >
                                        {arrImgs.map((img, index) => (
                                            <img key={index} className="img-fluid" src={process.env.PUBLIC_URL + img} alt={`Screenshot del trabajo de ${detailWork.projectTitle} realizado para ${detailWork.clientName}, dentro de nuestra area de ${detailWork.workTitle}, imagen número ${index + 1}`} />
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </Fade>


                    {detailWork.workUrl ?
                        <Slide right>
                            <div className="container botones">
                                <div className="row text-center justify-content-end">
                                    <div className="col-12 col-md-4">
                                        <a className="visitarWeb" href={detailWork.workUrl} target="_blank" rel="noopener noreferrer">Visitar Página</a>
                                    </div>

                                </div>
                            </div>
                        </Slide>
                        : null}





                    {similarWorks.length > 0 ?
                        <div className="container otrosProyectos">
                            <Zoom><h3 className="text-center title2">Mira otros proyectos similares</h3></Zoom>
                            <div className="row justify-content-around">

                                {similarWorks.slice(0, 3).map(
                                    similar => {
                                        let imgs = splitData(similar.productImages);
                                        return (<div key={similar.id} className="col-12 col-sm-6 col-md-3">
                                            <Link to={`/detalleTrabajo/${similar.amicalUrl}`}>
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + imgs[0]} alt={`Screenshot del trabajo de ${similar.projectTitle} realizado para ${similar.clientName}, dentro de nuestra area de ${similar.workTitle}`} />
                                                <h4 className="text-center tituloRel">Ver <FontAwesomeIcon icon="caret-square-right" /></h4>
                                            </Link>
                                        </div>)
                                    }
                                )}
                            </div>
                        </div>
                        : null}

                </div>


            </section>
        </Fragment>
    );
}

export default withRouter(WorkDetail);