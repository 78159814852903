export class DetalleTrabajo {
    idProyecto;
    idCategoriaProd;
    nombreCliente;
    titleAtr;
    metaDesc;
    metaKeywords;
    tituloProyecto;
    imgBanner;
    tituloTrabajo;
    descripcion;
    urlTrabajo;
    urlAmigable;
    imagenesProducto;
}