import React, { Fragment } from 'react';
import './sobreHL.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import logo from '../../multimedia/images/LOGO/black/HenLeiMD.svg';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import Roll from 'react-reveal/Roll';
import MetaTags from 'react-meta-tags';



const SobreHL = () => {

    window.scroll(0,0);

    const title = "Se parte de nuestra historia | Sobre HenLei MD";
	const description = "Te damos la bienvenida a HenLeiMD, más que un proveedor queremos ser tu aliado estratégico para llevar tu empresa al mundo digital por eso nuestro slogan es ¡Cambia tu mundo!";
	const keywords = "HenLei MD, Desarrollo Web, Marketing Digital, posicionamiento web, branding, estrategia digital, diseño web, Agencia de publicidad, Agencia de desarrollo, marketing online, agencia digital, publicidad web, diseño paginas web, creacion paginas web";
    const imgMeta = 'https://henleimd.com/multimedia/images/imgTags/home.jpg';
    

    return (
        <Fragment>

            <MetaTags>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />

                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={imgMeta} />

                <meta property="og:title" content={title} />
                <meta property="og:url" content="https://henleimd.com/sobreHL" />
                <meta property="og:image" content={imgMeta} />
                <meta property="og:description" content={description} />
                <meta property="og:site_name" content={title} />
                <link rel="canonical" href="https://henleimd.com/sobreHL"/>
            </MetaTags>

            <section className="mainSobreHL" id="mainSobreHL">
                <div className="container-fluid nopadding">
                    <Fade top>
                        <div className="head nopadding">
                            <div className="row opac">
                                <Slide left delay={200}>
                                    <div className="col-12 text-center">
                                        <span>¡¡¡Cambia tu Mundo!!!</span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </Fade>

                    <div className="row weare">
                        <div className="col-12">
                            <Flip top>
                                <h1 className="text-center"><img src={logo} alt="HenLei MD Logo"></img></h1>
                            </Flip>
                        </div>
                    </div>

                    <div className="row justify-content-center whowe">
                        <div className="col-md-9 col-lg-8 col-xl-7">
                            <Zoom>
                                <h2 className="text-center main-text">Pero ¿Qué significa HenLei?</h2>
                            </Zoom>
                            <Zoom>
                                <p className="text-center sigHL">
                                    HenLei simboliza liderazgo, nuestro nombre representa rapidez y el vínculo que tenemos con todas las personas alrededor del mundo gracias al Internet.<br /><br />Nuestro slogan “Cambia tu mundo” refleja la emoción que sentimos al ayudar a las empresas a expandirse a través del mundo digital y llevar sus productos, servicios y talentos al mundo. <br /><br />En HenLei tenemos la firme creencia de que lo que las personas queremos como usuarios, alguien lo ofrece exactamente como lo deseamos y lo que buscamos es ayudar a todos los usuarios a lograr esas conexiones generando un gran impacto en la forma de ver el universo.
                            </p>
                            </Zoom>
                        </div>
                    </div>

                    <Slide bottom>
                        <div className="whatwe">
                            <div className="row justify-content-center bgw nopadding">
                                <div className="col-12 col-md-4 mleft">
                                    <Zoom delay={200}>
                                        <h2 className="text-right">¿Qué hace HenLei?</h2>
                                    </Zoom>
                                    <p className="text-center text-sm-right"><b>Básicamente Desarrollo Web y Marketing Digital</b></p>
                                </div>

                                <div className="col-12 col-md-6">
                                    <Roll delay={400} >
                                        <p className="text-center text-sm-left">
                                            Para lograr conectar a las empresas con las personas nos basamos en las conexiones a través de páginas web, blogs, redes sociales, ecommerce, en resumen, medios digitales y ayudamos a que las páginas y sus contenidos, productos o servicios sean fáciles de encontrar para los usuarios tanto en los buscadores como en las redes sociales.
                                     </p>
                                    </Roll>
                                    <Slide bottom delay={400}>
                                        <div className="text-center">
                                            <Link to="/trabajos" className="btnhh">
                                                Ver Portafolio
						            </Link>
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    <div className="row forme justify-content-around">
                        <div className="col-12 col-sm-12 col-md-5 col-lg-6 text-center text-sm-right mleft">
                            <Zoom>
                                <h2>¿Qué puede hacer HenLei por mí?</h2>

                                <h3 className="tn"><b>Pues muy sencillo tu vida más fácil “Cambiamos tu mundo”.</b></h3>
                            </Zoom>
                        </div>

                        <div className="col-12 col-sm-12 col-sm-6 col-md-7 col-lg-6">
                            <ul>
                                <Zoom top>
                                    <li><FontAwesomeIcon icon='check' /> Te asesoramos de manera integral en la creación de una estrategia digital.</li>
                                    <li><FontAwesomeIcon icon='check' /> Te ayudamos a crear una página web profesional y adaptable a dispositivos móviles.</li>
                                    <li><FontAwesomeIcon icon='check' /> Creamos campañas digitales orientadas a branding o conversión.</li>
                                    <li><FontAwesomeIcon icon='check' /> Generamos contenidos de valor para tu página web.</li>
                                    <li><FontAwesomeIcon icon='check' /> Te ayudamos a aumentar el tráfico hacia tu página web.</li>
                                    <li><FontAwesomeIcon icon='check' /> Te ayudamos en la administración de las redes sociales de tu negocio para que el contacto sea más cercano.</li>
                                    <li><FontAwesomeIcon icon='check' /> Te ayudamos a posicionar tu página web en los primeros resultados de los buscadores mediante SEO y SEM.</li>
                                    <li><FontAwesomeIcon icon='check' /> Podemos crear una tienda en línea para tu negocio.</li>
                                </Zoom>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container-fluid nopadding">

                    <div className="row justify-content-around nuestroproceso">
                        <div className="col-12">
                            <Zoom><h2 className="text-center main-text">Nuestros Pilares</h2></Zoom>
                        </div>
                        <Zoom top cascade>
                            <div className="col-12 col-sm-10 col-md-6 col-lg-6 col-xl-4">
                                <div className="el1">
                                    <div className="bgosc">
                                        <h3>Gestión Humana</h3>
                                        <div className="text-center">
                                            <span>
                                                <FontAwesomeIcon icon="graduation-cap" className="icnPrs" />
                                            </span>
                                        </div>
                                        <p className="text-center">Para HenLei MD es importante cuidar a sus colaboradores quienes son nuestros amigos, por lo cual nos preocupamos por que nuestro cliente interno esté en condiciones óptimas para el desarrollo de sus labores, resaltando sus capacidades y brindando beneficios para lograr una cultura organizacional basada en la felicidad.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-10 col-md-6 col-lg-6 col-xl-4">
                                <div className="el2">
                                    <div className="bgosc">
                                        <h3>Know How</h3>
                                        <div className="text-center">
                                            <span>
                                                <FontAwesomeIcon icon="hands-helping" className="icnPrs" />
                                            </span>
                                        </div>
                                        <p className="text-center">Los procedimientos, conocimientos, técnicas y estrategias usadas por HenLei MD son un pilar fundamental para el desempeño de la organización, pues determinara la forma en la que trabajaremos de la mano con nuestros Stakeholders para lograr el éxito y la felicidad de nuestros clientes.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-10 col-md-6 col-lg-6 col-xl-4">
                                <div className="el3">
                                    <div className="bgosc">
                                        <h3>Tecnología</h3>
                                        <div className="text-center">
                                            <span>
                                                <FontAwesomeIcon icon="signal" className="icnPrs" />
                                            </span>
                                        </div>
                                        <p className="text-center">HenLei MD cuenta con tecnología de punta que permite el correcto funcionamiento de todas las herramientas para el diseño y desarrollo web, con el fin de ofrecer servicios de alta calidad que beneficien a nuestro cliente interno y externo en cada uno de los procesos de sus negocios.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-10 col-md-6">
                                <div className="el4">
                                    <div className="bgosc">
                                        <h3>Customer Journey</h3>
                                        <div className="text-center">
                                            <span>
                                                <FontAwesomeIcon icon="bezier-curve" className="icnPrs" />
                                            </span>
                                        </div>
                                        <p className="text-center">HenLei MD se interesa por el bienestar de sus clientes por lo cual nos preocupamos por conocer mejor a nuestra audiencia con el objetivo de diseñar las mejores estrategias para que sus negocios prosperen y alcancen el máximo potencial.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-10 col-xl-6">
                                <div className="el5">
                                    <div className="bgosc">
                                        <h3 className="text-center">Mejora continua</h3>
                                        <div className="text-center">
                                            <span>
                                                <FontAwesomeIcon icon="check-double" className="icnPrs" />
                                            </span>
                                        </div>
                                        <p className="text-center">HenLei MD se adapta a las necesidades del mercado, por lo cual siempre estará diseñando nuevas estrategias y planteando nuevos objetivos, obteniendo la mayor calidad posible en los servicios y procesos desarrollados y brindados a nuestros clientes. </p>
                                    </div>
                                </div>
                            </div>
                        </Zoom>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default SobreHL;