import React, { Fragment } from "react";
import Home from "./components/home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faPaintBrush, faAngleDoubleRight, faShapes, faBroadcastTower, faCartPlus, faFire, faCodeBranch,
  faPhone, faMobileAlt, faEnvelope, faBars, faUser, faAt, faCommentDots, faFileDownload, faArrowRight, faArrowDown, faAngleDoubleLeft,
  faVectorSquare, faUsers, faAlignCenter, faUserSecret, faBullhorn, faImages, faVideo, faEdit, faExternalLinkAlt, faCrosshairs, faUserCheck,
  faLayerGroup, faBullseye, faCompass, faCheckDouble, faChartBar, faPencilRuler, faAngleDown, faScrewdriver, faToolbox, faWrench, faCheck, 
  faCode, faComments, faChartLine, faBoxes, faGraduationCap, faHandsHelping, faSignal, faBezierCurve, faCaretSquareRight, faCircleNotch
} from '@fortawesome/free-solid-svg-icons';


import HeaderHome from "./components/layouts/header/Header";
import Marketing from "./components/marketing/Marketing";
import WebDev from "./components/webDev/WebDev";
import Works from "./components/works/Works";
import Terms from "./components/terms/Terms";
import Contacto from "./components/contacto/Contacto";
import SobreHL from "./components/sobreHL/SobreHL";
import SocialMedia from "./components/socialMedia/SocialMedia";
import Apps from "./components/apps/Apps";
import Footer from "./components/layouts/footer/Footer";
import Err404 from "./components/layouts/404/Err404";
import WorkDetail from "./components/works/workDetail/WorkDetail";



library.add(fab, faAngleDoubleRight, faShapes, faBroadcastTower, faPaintBrush, faCartPlus, faFire, faCodeBranch,
  faPhone, faMobileAlt, faEnvelope, faBars, faUser, faAt, faCommentDots, faFileDownload, faArrowRight, faArrowDown, faAngleDoubleLeft,
  faVectorSquare, faUsers, faAlignCenter, faUserSecret, faBullhorn, faImages, faVideo, faEdit, faExternalLinkAlt, faCrosshairs, faUserCheck, faLayerGroup,
  faBullseye, faCompass, faCheckDouble, faChartBar, faPencilRuler, faAngleDown, faScrewdriver, faToolbox, faWrench, faCheck, faCode, faComments, faChartLine, faBoxes,
  faGraduationCap, faHandsHelping, faSignal, faBezierCurve, faCaretSquareRight, faCircleNotch);

function App() {
  return (
    <Fragment>
      <Router>
        <HeaderHome />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/marketing" component={Marketing} />
          <Route exact path="/desarrollo" component={WebDev} />
          <Route exact path="/socialmedia" component={SocialMedia} />
          <Route exact path="/apps" component={Apps} />
          <Route exact path="/trabajos" component={Works} />
          <Route exact path="/terminos" component={Terms} />
          <Route exact path="/contacto" component={Contacto} />
          <Route exact path="/sobreHL" component={SobreHL} />
          {/* <Route exact path="/bloghl" component={UnderConstruct} /> */}
          <Route exact path="/detalletrabajo/:id" render={(props) => {
            return (<WorkDetail workID = {props.match.params.id} />)
          }} />
          <Route component={Err404} />
        </Switch>
        <Footer />
      </Router>
    </Fragment>
  );
}

export default App;
