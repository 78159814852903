let rotacion = true;


export function efecto_nav_bar_sections() {
    const doc = document.documentElement;
    let widthPage = window.innerWidth;
    let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    //padding: 0 0.5em;
    if (widthPage > 991) {

        if (top <= 200) {
            document.getElementById("navHL").style.backgroundColor = ("transparent");
            document.getElementById("imgLogoNav").style.minWidth = ("15em");
        } else if (top <= 300) {
            document.getElementById("navHL").style.backgroundColor = ("rgba(49, 59, 66, 0.2)");
            document.getElementById("imgLogoNav").style.minWidth = ("14em");
        } else if (top <= 400) {
            document.getElementById("navHL").style.backgroundColor = ("rgba(49, 59, 66, 0.4)");
            document.getElementById("imgLogoNav").style.minWidth = ("13em");

        } else if (top <= 500) {
            document.getElementById("navHL").style.backgroundColor = ("rgba(49, 59, 66, 0.6)");
            document.getElementById("imgLogoNav").style.minWidth = ("12em");

        } else {
            document.getElementById("navHL").style.backgroundColor = ("rgba(49, 59, 66, 0.8)");
            document.getElementById("imgLogoNav").style.minWidth = ("11em");
        }
    } else {
        if (top <= 200) {

            if (widthPage <= 575) {
                document.getElementById("imgLogoNav").style.minWidth = ("8em");
                document.getElementById("imgLogoNav").style.maxWidth = ("8em");
            } else {
                document.getElementById("imgLogoNav").style.minWidth = ("15em");
            }
            if (!rotacion) {
                document.getElementById("navHL").style.backgroundColor = ("rgba(49, 59, 66, 0.8)");
            } else {
                document.getElementById("navHL").style.backgroundColor = ("transparent");
            }
        } else {
            document.getElementById("navHL").style.backgroundColor = ("rgba(49, 59, 66, 0.8)");
            if (widthPage <= 575) {
                document.getElementById("imgLogoNav").style.minWidth = ("6em");
                document.getElementById("imgLogoNav").style.maxWidth = ("6em");
            } else {
                document.getElementById("imgLogoNav").style.minWidth = ("10em");
                document.getElementById("imgLogoNav").style.maxWidth = ("10em");
            }
        }

    }
}



export function effectsHH() {
    if (window.outerWidth < 992) {
        const hh = document.querySelector('.btnmenu');

        if (rotacion) {
            rotacion = false;
            hh.style.transform = `rotate(-180deg)`;
            document.getElementById("navHL").style.backgroundColor = ("rgba(49, 59, 66, 0.8)");

        }
        else {
            const doc = document.documentElement;
            let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
            rotacion = true;

            hh.style.transform = `rotate(0deg)`;
            if (top <= 200) {
                document.getElementById("navHL").style.backgroundColor = ("transparent");

            } else {
                document.getElementById("navHL").style.backgroundColor = ("rgba(49, 59, 66, 0.8)");
            }

        }
    }
}
