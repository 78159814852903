import React, { useEffect, useState } from 'react';
import LogoHenlei from '../../../multimedia/images/LOGO/white/Imagen HenLeiMD.svg';
import './header.scss';
import { Link } from "react-router-dom";

import { efecto_nav_bar_sections, effectsHH } from './effectsHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fade from 'react-reveal/Fade';

const HeaderHome = () => {

    useEffect(
        window.onscroll = () => efecto_nav_bar_sections()
    );


    // const [btnClass, setBtnClass] = useState('navbar-toggler collapsed');
    const [btnAriaExpand, setBtnAriaExpand] = useState(false);

    const [classNavBar, setClassNavBar] = useState('collapse navbar-collapse text-center justify-content-end menuHL');



    //

    const setData = () => {
        if (window.innerWidth < 992) {
            if (btnAriaExpand) {
                setBtnAriaExpand(false);
                setClassNavBar('collapse navbar-collapse text-center justify-content-end menuHL');
                effectsHH();
            } else {
                setBtnAriaExpand(true);
                setClassNavBar('navbar-collapse text-center justify-content-end text-center menuHL collapse show');
                effectsHH();
            }
        }
    }

    const setDataBtn = () => {
        if (btnAriaExpand) {
            setBtnAriaExpand(false);
            effectsHH();
            setClassNavBar('collapse navbar-collapse text-center justify-content-end menuHL');
        } else {
            setBtnAriaExpand(true);
            setClassNavBar('navbar-collapse text-center justify-content-end text-center menuHL collapse show');
            effectsHH();
        }
    }

    return (
        <Fade top>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top justify-content-end" id="navHL">

                <div className="container">
                    <div className="col col-lg-3 col-xl-2 brandhl" id="brandhl">
                        <Link to="/" className="navbar-brand">
                            <img className="img-fluid" id="imgLogoNav" src={LogoHenlei} alt="HenLei MD Logo" />
                        </Link>
                    </div>

                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarHome" aria-controls="navbarHome" aria-expanded="false" aria-label="Toggle">
                        <FontAwesomeIcon icon="bars" className="btnmenu"
                            onClick={setDataBtn}
                        />
                    </button>

                    <div className={classNavBar} id="navbarHome">
                        <ul className="navbar-nav">
                            <li className="nav-item ppp">
                                <Link className="nav-link" to="/" onClick={setData}>INICIO</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">SERVICIOS</Link>
                                <div className="dropdown-menu text-center" aria-labelledby="navbarDropdownMenuLink">
                                    <Link className="dropdown-item" to="/marketing" onClick={setData}>MARKETING</Link>
                                    <Link className="dropdown-item" to="/desarrollo" onClick={setData}>DESARROLLO</Link>
                                    <Link className="dropdown-item" to="/socialmedia" onClick={setData}>SOCIAL MEDIA</Link>
                                    <Link className="dropdown-item" to="/apps" onClick={setData}>APPS</Link>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/trabajos" onClick={setData}>TRABAJOS</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://blog.henleimd.com/" target="_blank" rel="noopener noreferrer" onClick={setData}>BLOG</a>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/sobreHL" onClick={setData}>SOBRE HL</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contacto" onClick={setData}>CONTACTO</Link>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        </Fade>
    );
}

export default HeaderHome;