import React, { Fragment } from 'react';
import './socialmedia.scss';
import { Link } from 'react-router-dom';
import SectionContactChat from '../layouts/sectionContactChat/SectionContactChat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import chattingMan from '../../multimedia/images/socialMedia/face/chattingMan.jpg';
import justLikes from '../../multimedia/images/socialMedia/face/justLikes.jpg';
import computerInstagram from '../../multimedia/images/socialMedia/inst/computerInstagram.jpg';
import cellInstagram from '../../multimedia/images/socialMedia/inst/cellInstagram.jpg';
import macYoutube from '../../multimedia/images/socialMedia/ytube/macYoutube.jpg';
import logoYoutube from '../../multimedia/images/socialMedia/ytube/logoYoutube.png';
import celltwitter from '../../multimedia/images/socialMedia/twitt/celltwitter.jpg';
import tweets from '../../multimedia/images/socialMedia/twitt/twits.jpg';
import smm from '../../multimedia/images/socialMedia/smm.jpg';
import smo from '../../multimedia/images/socialMedia/smo.jpg';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import MetaTags from 'react-meta-tags';

const SocialMedia = () => {

    window.scroll(0,0);

    const title = "HenLei MD - Social Media";
	const description = "La calidad y la originalidad son nuestra prioridad, te invitamos a ver nuestros trabajos de social media y seas testigo de ello.";
	const keywords = "HenLei MD, Marketing Digital, publicidad en redes, creacion de contenidos, redes sociales, creación redes sociales, social media, commumity mannager, influencer, social media marketing, social media manager, marketing en redes sociales, agencia de redes sociales, agencia de social media";
	const imgMeta = 'https://henleimd.com/multimedia/images/imgTags/socialmedia.jpg';

    return (
        <Fragment>

            <MetaTags>
				<title>{title}</title>
				<meta name="description" content={description}/>
				<meta name="keywords" content={keywords} />

				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				<meta name="twitter:image" content={imgMeta} />

				<meta property="og:title" content={title} />
				<meta property="og:url" content="https://henleimd.com/socialmedia" />
				<meta property="og:image" content={imgMeta} />
				<meta property="og:description" content={description} />
				<meta property="og:site_name" content={title} />
                <link rel="canonical" href="https://henleimd.com/socialmedia"/>
			</MetaTags>

            <section className="mainSM" id="mainSM">
                <Fade top>
                    <div className=" container-fluid head nopadding justify-content-center">
                        <div className="row opac">
                            <div className="col-12">
                                <Slide left delay={200}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="main-text">SOCIAL MEDIA</h1>
                                        </div>
                                    </div>

                                    <div className="row justify-content-end">
                                        <div className="col-12 col-md-8 col-lg-7 col-xl-6">
                                            <h2>Usamos las mejores tecnologías del mercado para darte soluciones optimas y a la medida </h2>
                                        </div>
                                    </div>
                                </Slide>
                                <Zoom delay={200}>
                                    <div className="row justify-content-end">
                                        <div className="col-12 col-sm-6">
                                            <Link className="btn" to="/trabajos">Conoce nuestro estilo</Link>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                    </div>
                </Fade>

                <div className="container-fluid SsAdminRs">
                    <div className="row nopadding">
                        <div className="col-12 nopadding">
                            <Zoom>
                                <h2 className="text-center main-text">Administración de Redes Sociales</h2>
                            </Zoom>
                            <Zoom>
                                <h3 className="text-center sbtlt">Servicios de Commumuty Manager – Scripts y Publicaciones</h3>
                            </Zoom>
                        </div>
                    </div>


                    <div className="row nopadding justify-content-around">
                        <Zoom cascade>
                            <div className="col-8 col-md-6 col-lg-3 imgs">
                                <div className="row">
                                    <div className="col-10">
                                        <img className="img-fluid" src={chattingMan} alt="Campañas en Facebook" />
                                    </div>
                                </div>
                                <div className="row nopadding upleft justify-content-end">
                                    <div className="col-10">
                                        <img className="img-fluid" src={justLikes} alt="Mejorar la relación con los clientes" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="text-center titleF pttitle">Facebook</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="text-center text">Damos a conocer tu marca y tus productos a través de la red social más grande del mundo, administrando tu red y creando publicidad dirigida a tu mercado objetivo.</p>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-5">
                                        <FontAwesomeIcon icon="bullhorn" className="fa-bullhorn icono"></FontAwesomeIcon>
                                    </div>
                                </div>
                            </div>



                            <div className="col-12 col-md-6 col-lg-3 order-2 order-md-1">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="text-center titleI pttitle">Instagram</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="text-center text">Una imagen dice más que mil palabras, en HenLei te ayudamos a crear contenido de valor para administrar esta red social que es cada vez más importante para el branding.</p>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-5">
                                        <FontAwesomeIcon icon="images" className="fa-images icono" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-8 col-md-6 col-lg-3 order-1 order-md-2 imgs">
                                <div className="row  justify-content-end">
                                    <div className="col-10">
                                        <img className="img-fluid" src={computerInstagram} alt="Campañas en Instagram" />
                                    </div>
                                </div>
                                <div className="row nopadding upRight justify-content-start">
                                    <div className="col-10">
                                        <img className="img-fluid" src={cellInstagram} alt="Administración de redes sociales Instagram" />
                                    </div>
                                </div>
                            </div>
                        </Zoom>
                    </div>



                    <div className="row nopadding justify-content-around goup">
                        <Zoom cascade>
                            <div className="col-8 col-md-6 col-lg-3 imgs">
                                <div className="row">
                                    <div className="col-10">
                                        <img className="img-fluid" src={macYoutube} alt="Campañas en YouTube" />
                                    </div>
                                </div>
                                <div className="row nopadding upleft justify-content-end">
                                    <div className="col-10">
                                        <img className="img-fluid" src={logoYoutube} alt="Creación de contenidos para YouTube" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="text-center titleY pttitle">YouTube</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="text-center text">Cada vez la publicidad en YouTube es más común y desde esta plataforma usada por millones de personas te podemos ayudar para que tu marca logre reconocimiento, promociones tus eventos y tus productos.</p>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-5">
                                        <FontAwesomeIcon icon="video" className="fa-video icono" />
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 col-md-6 col-lg-3 order-2 order-md-1">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="text-center titleT pttitle">Twitter</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="text-center text">En Twitter las personas se enteran de lo que sucede en el mundo entero. HenLei te ayuda a que el mundo entero se conecte con tu negocio.</p>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-5">
                                        <FontAwesomeIcon icon="edit" className="fa-edit icono" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-8 col-md-6 col-lg-3 order-1 order-md-2 imgs">
                                <div className="row  justify-content-end">
                                    <div className="col-10">
                                        <img className="img-fluid" src={celltwitter} alt="Campañas en Twitter" />
                                    </div>
                                </div>
                                <div className="row nopadding upRight justify-content-start">
                                    <div className="col-10">
                                        <img className="img-fluid" src={tweets} alt="Administración de redes sociales Twitter" />
                                    </div>
                                </div>
                            </div>
                        </Zoom>
                    </div>

                </div>

                <div className="container socialCampains">
                    <div className="row nopadding">
                        <div className="col-12 nopadding">
                            <Zoom><h2 className="text-center main-text">Estrategias de Social Media</h2></Zoom>
                            <Zoom><h3 className="text-center subtlt">Te ayudamos a</h3></Zoom>
                        </div>
                    </div>

                    <div className="row">
                        <Slide left>
                            <div className="col-12 col-md-5 text-center">
                                <h4 className="TitleEstSM">Establecer y complir Objetivos</h4>
                                <FontAwesomeIcon icon="crosshairs" className="icono" />
                            </div>
                        </Slide>
                        <div className="col-12 col-md-7">
                            <p className="tEstSM">Basándonos en tus expectativas, y en todo aquellos que esperas lograr a través de las redes sociales crearemos una estrategia con objetivos medibles que nos permitan lograr todo aquello que deseas para tu negocio: Branding, crear o aumentar una comunidad, generar un contacto directo con tus clientes, vender más, generar más tráfico hacia tu web, etc.</p>
                        </div>
                    </div>
                    <div className="row">
                        <Slide left delay={100}>
                            <div className="col-12 col-md-5 text-center">
                                <h4 className="TitleEstSM">Conocer tu verdadero público</h4>
                                <FontAwesomeIcon icon="user-check" className="icono" />
                            </div>
                        </Slide>
                        <div className="col-12 col-md-7">
                            <p className="tEstSM">Tomando como punto de partida tu tipo de negocio y todo aquello que quieres lograr (tus objetivos) escogeremos el público objetivo al que debemos alcanzar de acuerdo con su edad, localización geográfica, intereses, entre otros aspectos importantes, y crearemos los mensajes correctos para está audiencia, está es la etapa más importante de nuestro proceso ya que de esto dependen los resultados.</p>
                        </div>

                    </div>
                    <div className="row">
                        <Slide left delay={200}>
                            <div className="col-12 col-md-5 text-center">
                                <h4 className="TitleEstSM">Escoger los canales de comunicación</h4>
                                <FontAwesomeIcon icon="layer-group" className="icono" />
                            </div>
                        </Slide>

                        <div className="ccol-12 col-md-7">
                            <p className="tEstSM">De acuerdo con el perfil de persona al que quieres alcanzar y el mensaje que queramos transmitir escogeremos el conjunto de redes sociales adecuados para publicar el mensaje, esto dependerá de cuales redes se adaptan más a tu tipo de negocio, aquello que queremos transmitir y a quien lo queremos transmitir y por supuesto el momento perfecto para trasmitirlo.</p>
                        </div>
                    </div>

                </div>


                <div className="container-fluid SMO">
                    <div className="row nopadding justify-content-lg-around">
                        <div className="col-10 col-md-8 col-lg-5 nopadding">
                            <Zoom><h2 className="text-center main-text">Social Media Optimization</h2></Zoom>
                            <Zoom><h3 className="text-center subtlt">SMO</h3></Zoom>
                            <p><Flip left>Para que tengas éxito en los medios sociales es completamente necesario tener una estrategia que te permita sobresalir, por eso te brindamos soluciones de optimización en tus redes sociales, apoyándote en los siguientes aspectos:</Flip></p>
                            <ul>
                                <Flip left cascade>
                                    <li>Sincronía entre redes sociales y tu página web</li>
                                    <li>Optimización de contenidos para que sean fáciles de encontrar</li>
                                    <li>Facilitar a tus usuarios la difusión de contenidos</li>
                                    <li>Manejo de redes a través de plataformas profesionales</li>
                                    <li>Adaptación de contenidos a diferentes plataformas</li>
                                </Flip>
                            </ul>
                        </div>
                        <Slide right>
                            <div className="d-none d-lg-block col-lg-6">
                                <div className="bg">
                                    <img src={smo} alt="Optimización de redes sociales" />
                                </div>
                            </div>
                        </Slide>
                    </div>
                </div>

                <div className="container-fluid SMM">
                    <div className="row nopadding justify-content-end justify-content-lg-around">
                        <Slide left>
                            <div className="d-none d-lg-block col-lg-6">
                                <div className="bg">
                                    <img src={smm} alt="Marketing en redes sociales" />
                                </div>
                            </div>
                        </Slide>
                        <div className="col-10 col-md-8 col-lg-5 nopadding">
                            <Zoom><h2 className="text-center main-text">Social Media Marketing</h2></Zoom>
                            <Zoom><h3 className="text-center subtlt">SMM</h3></Zoom>
                            <p> <Flip left> La publicidad en redes sociales es un hecho, y hoy día esto resulta imprescindible para dar fuerza a cualquier empresa, marca o producto. En HenLei MD, te ayudamos a crear campañas basándonos en nuestra estrategia y así tus campañas puedan:</Flip></p>
                            <ul>
                                <Flip left cascade>
                                    <li>Sincronía entre redes sociales y tu página web</li>
                                    <li>Optimización de contenidos para que sean fáciles de encontrar</li>
                                    <li>Facilitar a tus usuarios la difusión de contenidos</li>
                                    <li>Manejo de redes a través de plataformas profesionales</li>
                                    <li>Adaptación de contenidos a diferentes plataformas</li>
                                </Flip>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>




            <SectionContactChat />

        </Fragment>
    );
}

export default SocialMedia;