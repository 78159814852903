import React, { Fragment } from 'react';
import './terms.scss';
import MetaTags from 'react-meta-tags';

const Terms = () => {

    window.scroll(0,0);
    
    const title = "Términos y Condiciones | HenLei MD";
    const description = "Conoce los términos y condiciones de HenLei MD, y la forma en que protegemos tus datos, cuidar tu información es muy importante para nosotros.";
    const keywords = "HenLei MD, Desarrollo Web, Marketing Digital, posicionamiento web, branding, estrategia digital, diseño web, Agencia de publicidad, Agencia de desarrollo, marketing online, agencia digital, publicidad web, diseño paginas web, creacion paginas web";
    const imgMeta = 'https://henleimd.com/multimedia/images/imgTags/terms.jpg';


    return (

        <Fragment>
            <MetaTags>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />

                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={imgMeta} />

                <meta property="og:title" content={title} />
                <meta property="og:url" content="https://henleimd.com/terminos" />
                <meta property="og:image" content={imgMeta} />
                <meta property="og:description" content={description} />
                <meta property="og:site_name" content={title} />
                <link rel="canonical" href="https://henleimd.com/terminos"/>
            </MetaTags>
            <section id="terms-cond">
                <div className="container-fluid nopadding">

                    <div className=" container-fluid head nopadding">
                        <div className="row opac">
                            <div className="col-12 text-center">
                                <h1 className="text-center">Política de Privacidad y <br /> Tratamiento de Datos Personales</h1>
                            </div>
                        </div>
                    </div>


                    <div className="container">
                        <div className="row infoemp">
                            <div className="col-12">
                                <p>HenLei MD, dando cumplimiento a lo establecido en el artículo 13 del Decreto 1377 de 2013, el cual establece las Políticas de Tratamiento de la Información, les informa a todos sus usuarios que a partir del 20 de octubre de 2018 se instituye esta Política de Privacidad y Tratamiento de Datos personales, con la cual la información de clientes, proveedores, empleados, intermediarios y demás usuarios será recibida y usada acorde a los dispuesto en la ley.</p>
                                <p>HenLei MD en cumplimiento de lo estipulado por la Ley 1581 de 2012, sobre la protección de datos personales, que establece “desarrollar el derecho constitucional que tienen todas las personas a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas en bases de datos o archivos", respeta la confidencialidad y seguridad de la información de los usuarios, y procura por la preservación los datos que se proporcionen en todos los sitios web de la empresa.</p>
                                <p>El objetivo de la presente Política es poner a disposición de todos los usuarios los términos bajos los cuales serán tratados sus datos personales, alineando la política de privacidad a las políticas internas de la organización, se resumen los derechos consagrados en la ley y en la constitución, las finalidades del tratamiento, los canales de comunicación disponibles para atender consultas y reclamos.</p>
                                <h3>Responsable del tratamiento</h3>
                                <p> HenLei MD sociedad legalmente constituida, domiciliada en la Carrera 10b # 49H 62, Bogotá D.C, teléfono fijo (+57-1) 2004649, dando cumplimiento a lo dispuesto en la Ley 1581 de 2012 y al Decreto 1377 de 2013 es responsable del tratamiento de datos personales de los titulares respecto de los cuales obtenga autorización, sobre los cuales decidirá de forma directa y autónoma.</p>
                                <h3>Derechos de los usuarios</h3>
                                <ul>
                                    <li>Acceder, conocer, actualizar, rectificar o suprimir sus datos personales.</li>
                                    <li>Conocer la política de privacidad y tratamiento de datos.</li>
                                    <li>Recibir información sobre el uso de sus datos personales.</li>
                                    <li>Anular la autorización del uso de los datos cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales.</li>
                                    <li>Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a la Ley.</li>
                                </ul>
                                <h3>Deberes de HenLei como responsable</h3>
                                <ul>
                                    <li>Garantizar a los usuarios todos los derechos consagrados en la presente política.</li>
                                    <li>Solicitar y conservar, en las condiciones previstas en la ley, la autorización otorgada por los usuarios para el manejo de su información.</li>
                                    <li>Mantener la información de los usuarios bajo condiciones altas de seguridad.</li>
                                    <li>Informar a los usuarios sobre las finalidades con las cuales van a ser usados sus datos personales de acuerdo con la normativa vigente.</li>
                                </ul>
                                <h3>Tratamiento</h3>
                                <p> HenLei MD en cumplimiento con la ley y bajo la premisa de la protección a la intimidad de todos los ciudadanos, pretende dar un tratamiento adecuado a la información suministrada por todos los usuarios, administrando la información con los controles necesarios para proteger la integridad de los usuarios.</p>
                                <p>La presente política establece los términos, condiciones y finalidades bajo las cuales HenLei MD, como responsable de los datos personales obtenidos a través de sus distintos canales, trata la información de todas las personas que en algún momento por razones de la actividad que desarrolla la entidad hayan suministrado datos personales. Se da la libertad de que el usuario suministre, registre o entregue su información de manera voluntaria una vez haya leído la presente política y decida aceptar expresamente los términos y condiciones. Se aclara que la información de las bases de datos de la empresa no se comercializa y se mantiene bajo privacidad solamente para el administrador del sitio.</p>
                                <h3>Finalidades</h3>
                                <ul>
                                    <li>Envío de información relevante acerca de campañas, nuevos servicios y mejoras.</li>
                                    <li>Mantener actualizados a los usuarios sobre las condiciones del servicio y las políticas de la compañía.</li>
                                    <li>Estudios de mercado para la implementación de nuevas estrategias comerciales.</li>
                                    <li>Para el cobro de cartera.</li>
                                    <li>Realizar proceso de selección, evaluación, y vinculación laboral.</li>
                                    <li>Con el fin de adoptar medidas para prevenir el desarrollo de actividades ilícitas y para otros fines tributarios de acuerdo con lo dispuesto por la ley.</li>
                                    <li>Enviar información comercial, publicitaria y promocional sobre los servicios, campañas y eventos desarrollados por HenLei MD con el fin de impulsar las actividades comerciales de la empresa.</li>
                                    <li>Es deber del cliente garantizar la veracidad de los datos facilitados por él y de aquellos por los que es responsable.</li>
                                </ul>
                                <h3>Vigencia del Tratamiento</h3>
                                <p> La presente Política rige a partir del veinte (20) de octubre de 2018 y el periodo de las bases de datos tendrá una vigencia igual al periodo en que se mantenga la finalidad o finalidades, o el periodo de vigencia que señale una causa legal, contractual o jurisprudencial de manera específica.</p>
                                <h3>Modificaciones</h3>
                                <p>HenLei MD S.A.S se reserva el derecho de revisar, modificar y actualizar esta Política de Uso en cualquier momento de forma completa o parcial y sin previo aviso. Dichas modificaciones serán notificadas por correo electrónico y/o publicadas en el sitio web de henleimd.com, si transcurridos treinta (30) días desde el momento de la notificación y/o publicación de las modificaciones y/o actualizaciones el usuario no manifiesta su deseo de retirar y/o actualizar su información de las bases de datos de HenLei MD y/o continua usando los servicios de HenLei MD y/o el portal web se dará como entendido que ha aceptado las modificaciones realizadas y los datos personales que sean almacenados, utilizados o transmitidos permanecerán en nuestra Base de Datos, con base en el criterio de temporalidad y necesidad, durante el tiempo que sea necesario para las finalidades mencionadas en esta Política, para las cuales fueron recolectados.</p>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
        </Fragment>

    );
}

export default Terms;