import React from 'react';
import './error.scss';

const Erros = ({ message }) => {

    return (
        <div className="error">
            <ul>
                {message.map((error, index) => (
                    <li key={index}>{error}</li>
                ))}
            </ul>
        </div>
    );
}

export default Erros;